<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Career</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Careers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h1>Career</h1>
                        <p>"Kautilyam extends a warm invitation to all IT professionals, whether you're a fresher or
                            seasoned in the field, to become a part of our expanding team and family. At Kautilyam, we
                            provide the perfect environment to nurture and elevate your career within the dynamic IT
                            industry. As a leading company specializing in Web-App and Blockchain Development, we
                            believe in the ethos of growth through collaboration and mutual success. Join us as we
                            journey together towards excellence.</p>
                        <div class="features-text">
                            <p>We are having a bunch of passionate, adventurous and crazy head developers, who always
                                thrive to serve humbleness and die for each other.</p>
                        </div>
                        <div class="features-text">
                            <p>Dive into the kaleidoscope of our eccentric ideas</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/career/career.png" alt="image"> </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="tab-section pb-100">
    <div class="container">
        <div class="section-title">
            <h2>What makes us different?</h2>
            <p>Blockchain Technology is becoming incredibly popular. We believe in pairing fresh ideas with passion. Our
                expertise in Web-App and Blockchain Development will always serve the best in all forms. Our goal is to
                match innovation with new possibilities.</p>
            <div class="bar"></div>
        </div>
        <div class="schedule-list-tab">
            <div class="tabs row">
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa fa-arrow-up-right-dots"></i> Growth Opportunity</span></a></li>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa-solid fa-gift"></i>Celebrations</span></a></li>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa fa-person-walking-luggage"></i>Career Development</span></a></li>
                    </div>
                </div>
            </div>
            <ul class="tabs row">
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa-regular fa-square-plus"></i> Monetary Benefits</span></a></li>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa-solid fa-seedling"></i>Quality work Environment</span></a></li>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="services-details-image">
                        <li><a><span><i class="fa fa-chalkboard-user"></i>Training & Development</span></a></li>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</section>