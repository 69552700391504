import { Component } from '@angular/core';

@Component({
  selector: 'app-python',
  templateUrl: './python.component.html',
  styleUrl: './python.component.scss'
})
export class PythonComponent {

}
