<div class="navbar-area">
        <div class="container">
                <nav class="navbar navbar-expand-md navbar-light"> <a class="navbar-brand" routerLink="/"> <img
                                        src="assets/img/logo.webp" alt="logo"> </a> <button class="navbar-toggler"
                                type="button" (click)="toggleSidebar()"> <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" #sidebarElement>
                                <ul class="navbar-nav desktop">
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/about"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                        <li class="nav-item"> <a class="nav-link">Services <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/frontend-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Frontend
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/backend-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Backend
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/app-development" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">App
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blockchain" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blockchain</a>
                                                        </li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/e-commerce-solution"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">E-Commerce
                                                                        Solutions </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/graphic-design" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Graphic
                                                                        Design </a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link">Technologies <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link">Front-end <i
                                                                                class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu ">
                                                                        <li class="nav-item"><a routerLink="/html"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">HTML </a></li>
                                                                        <li class="nav-item"><a routerLink="/css"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">CSS </a></li>
                                                                        <li class="nav-item"><a routerLink="/javascript"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Javascript </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/angular"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> Angular </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/react-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> React Js </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/next-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> Next Js </a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link">Backend-end <i
                                                                                class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu sub-dropdown">
                                                                        <li class="nav-item"><a routerLink="/dot-net"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> ASP.NET </a>
                                                                        </li>
                                                                        <li class="nav-item"> <a routerLink="/node-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Node JS </a>
                                                                        </li>
                                                                        <li class="nav-item"> <a routerLink="/python"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Python</a></li>
                                                                        <li class="nav-item"> <a routerLink="/c-sharp"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">C Sharp</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link">App <i
                                                                                class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"> <a routerLink="/android"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Andriod </a></li>
                                                                        <li class="nav-item"> <a routerLink="/java"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Java </a></li>
                                                                        <li class="nav-item"> <a routerLink="/swift"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Swift</a>
                                                                        </li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/react-native"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">React
                                                                                        Native</a></li>
                                                                        <li class="nav-item"><a routerLink="/c-plus"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">C/C++</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link">Platforms <i
                                                                                class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item"> <a routerLink="/aws"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        AWS </a></li>
                                                                        <li class="nav-item"> <a routerLink="/azure"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Azure </a></li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/portfolio"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Portfolio</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/carrers"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a> </li> -->
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/contact-us"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                                </ul>
                                <ul class="navbar-nav mobile">
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/about"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                        <li class="nav-item"> <a class="nav-link" (click)="toggleNavbar()">Services <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu" *ngIf="isNavbarOpen">
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/frontend-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Frontend
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/backend-development"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Backend
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/app-development" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">App
                                                                        Development</a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blockchain" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blockchain</a>
                                                        </li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/e-commerce-solution"
                                                                        class="nav-link" routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">E-Commerce
                                                                        Solutions </a></li>
                                                        <li class="nav-item" data-bs-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/graphic-design" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Graphic
                                                                        Design </a></li>
                                                </ul>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link"
                                                        (click)="toggleTechnologies()">Technologies <i
                                                                class='bx bx-chevron-down'></i></a>
                                                <ul class="dropdown-menu" *ngIf="isTech">
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link" (click)="frontend()">Front-end
                                                                        <i class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu" *ngIf="isfrontend">
                                                                        <li class="nav-item"><a routerLink="/html"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">HTML </a></li>
                                                                        <li class="nav-item"><a routerLink="/css"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">CSS </a></li>
                                                                        <li class="nav-item"><a routerLink="/javascript"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Javascript </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/angular"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> Angular </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/react-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> React Js </a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/next-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> Next Js </a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link" (click)="backend()">Backend-end
                                                                        <i class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu sub-dropdown"
                                                                        *ngIf="isBackend">
                                                                        <li class="nav-item"><a routerLink="/dot-net"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link"> ASP.NET </a>
                                                                        </li>
                                                                        <li class="nav-item"> <a routerLink="/node-js"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Node JS </a>
                                                                        </li>
                                                                        <li class="nav-item"> <a routerLink="/python"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">Python</a></li>
                                                                        <li class="nav-item"> <a routerLink="/c-sharp"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}"
                                                                                        class="nav-link">C Sharp</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link" (click)="app()">App <i
                                                                                class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu" *ngIf="isApp">
                                                                        <li class="nav-item"> <a routerLink="/android"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Andriod </a></li>
                                                                        <li class="nav-item"> <a routerLink="/java"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Java </a></li>
                                                                        <li class="nav-item"> <a routerLink="/swift"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Swift</a>
                                                                        </li>
                                                                        <li class="nav-item"><a
                                                                                        routerLink="/react-native"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">React
                                                                                        Native</a></li>
                                                                        <li class="nav-item"><a routerLink="/c-plus"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">C/C++</a>
                                                                        </li>
                                                                        <li class="nav-item"><a routerLink="/kotlin"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Kotlin</a>
                                                                        </li>
                                                                </ul>
                                                        </li>
                                                        <li class="nav-item"><a href="javascript:void(0)"
                                                                        class="nav-link" (click)="platforms()">Platforms
                                                                        <i class='bx bx-chevron-right'></i></a>
                                                                <ul class="dropdown-menu" *ngIf="isPlatform">
                                                                        <li class="nav-item"> <a routerLink="/aws"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        AWS </a></li>
                                                                        <li class="nav-item"> <a routerLink="/azure"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">
                                                                                        Azure </a></li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/portfolio"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Portfolio</a></li>
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/carrers"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a> </li> -->
                                        <li class="nav-item" data-bs-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/contact-us"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                                </ul>
                        </div>
                </nav>
        </div>
</div>