import { Component } from '@angular/core';

@Component({
  selector: 'app-next-js',
  templateUrl: './next-js.component.html',
  styleUrl: './next-js.component.scss'
})
export class NextJsComponent {

}
