<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>React js</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>REACT JS DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Specializing in creating high-performance, scalable applications, we leverage the power of React
                        to transform your digital presence, ensuring a seamless and engaging user experience across all
                        platforms. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified React specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>100 + </h2>
                            <p>React projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with React development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Advantages of React.js </h3>
            <p>Wondering what makes React.js development so special? The library is recommended for companies that want
                to gain a competitive edge over their rivals. It allows us to build everything that concerns putting
                together visual elements, binding data to those elements, and specifying the logic governing them. Here
                are the biggest advantages of React.js: </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/simplicity.svg" alt="image"> </div>
                    <h3>Simplicity</h3>
                    <p>React.js promotes clear and understandable code, making it easy for developers to work with and
                        maintain. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/time.svg" alt="image"> </div>
                    <h3>Time-saving </h3>
                    <p> With React.js, developers can build innovative web and mobile applications quickly, reducing
                        time-to-market significantly. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/popularity.svg" alt="image"> </div>
                    <h3>Popularity </h3>
                    <p> React.js is one of the most popular libraries globally, suitable for both simple apps and
                        enterprise-level solutions. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/versatile.svg" alt="image"> </div>
                    <h3>Versatility</h3>
                    <p>React.js enables rapid development through its component-based architecture and extensive library
                        of available components.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/experience.svg" alt="image"> </div>
                    <h3>Native user experience </h3>
                    <p> React.js enables developers to effortlessly create beautiful, responsive, and highly interactive
                        user interfaces. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/flexibility.svg" alt="image"> </div>
                    <h3>Development flexibility </h3>
                    <p>React.js supports the development of various types of software, from small websites to
                        large-scale enterprise systems.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/performance.svg" alt="image"> </div>
                    <h3>World-class performance </h3>
                    <p>React.js utilizes virtual DOMs to enhance performance speed, leading to smoother operations and
                        better user experiences.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/community.svg" alt="image"> </div>
                    <h3>Supportive community </h3>
                    <p>React.js benefits from a supportive community, providing ample resources for troubleshooting and
                        innovation. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/seo.svg" alt="image"> </div>
                    <h3>SEO </h3>
                    <p>React.js facilitates effective server-side rendering, improving SEO algorithms and making
                        applications more search engine-friendly. </p>
                </div>
            </div>
        </div>
    </div>
</section>