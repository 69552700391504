<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>C/C++</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>C/C++ DEVELOPMENT SERVICES </h3>
                    <div class="bar"></div>
                    <p>Utilizing best practices and C++ standards, our C++ development team writes clean source code,
                        creates clear documentation and offers unique solutions that consistently outperform the
                        competition. We assist at each stage product development and deliver end-to-end software.
                        solutions</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>20 +</h2>
                            <p>qualified C/C++ specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>40 +</h2>
                            <p>C/C++ projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with C/C++ development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>BENEFITS OF C/C++ DEVELOPMENT </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/project-objective.svg" alt="image"> </div>
                    <h3>Project Objectives Understanding </h3>
                    <p>Thoroughly comprehend the objectives and desired outcomes of the project.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/assessment.svg" alt="image"> </div>
                    <h3>Client Requirements Assessment </h3>
                    <p>Assess and analyze the specific requirements and expectations of the client.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/scope.svg" alt="image"> </div>
                    <h3>Scope Definition </h3>
                    <p>Define the scope of the project, outlining its boundaries, objectives, and deliverables.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/functional-requirement.svg" alt="image"> </div>
                    <h3>Functional Requirement Gathering </h3>
                    <p>Gather and document the functional requirements needed to meet the project objectives.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/feasibility-analysis.svg" alt="image"> </div>
                    <h3>Technical Feasibility Analysis</h3>
                    <p>Analyze the technical feasibility of the project, considering available resources and technology.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/development-cycle.svg" alt="image"> </div>
                    <h3>Risk Mitigation Strategy Development</h3>
                    <p>Develop a strategy to identify, assess, and mitigate risks throughout the project lifecycle.</p>
                </div>
            </div>
        </div>
    </div>
</section>