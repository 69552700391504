import { Component, OnInit } from '@angular/core';
import { PortfolioItems } from './portfolio.items';

@Component({
  selector: 'app-projects-two',
  templateUrl: './projects-two.component.html',
  styleUrls: ['./projects-two.component.scss']
})
export class ProjectsTwoComponent implements OnInit {
  public porfolioDetails = PortfolioItems;
  constructor() { }

  ngOnInit(): void {
  }

}
