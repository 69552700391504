<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Android</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>ANDROID APP DEVELOPMENT SERVICE </h3>
                    <div class="bar"></div>
                    <p>Crafting seamless experiences for the Android ecosystem: Tailored solutions that resonate with
                        your audience. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>mobile development experts </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>60 + </h2>
                            <p>projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars </h2>
                            <p>overall review rating based on 55+ reviews </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience in business </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>ANDROID SOLUTIONS TO INDUSTRY-SPECIFIC NEEDS </h3>
            <p>Bridging the gap between technology and diverse industries with customized Android applications that
                drive growth and innovation. </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/aducation.svg" alt="image"> </div>
                    <h3>Education</h3>
                    <p>Crafting Android apps that enhance e-learning, streamline tasks, and foster student engagement.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/healthcare.svg" alt="image"> </div>
                    <h3>Healthcare</h3>
                    <p>Delivering Android solutions for telemedicine, appointment scheduling, and health monitoring.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/fintech.svg" alt="image"> </div>
                    <h3>Fintech</h3>
                    <p>Designing secure Android apps for banking, insurance, and fintech with intuitive user interfaces.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/ecommerce.svg" alt="image"> </div>
                    <h3>E-Commerce</h3>
                    <p>Building Android platforms for seamless product browsing, secure payments, and customer
                        engagement. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/automative.svg" alt="image"> </div>
                    <h3>Automative</h3>
                    <p> Designing apps that integrate with vehicle systems, offering navigation, diagnostics, and in-car
                        entertainment.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/gis.svg" alt="image"> </div>
                    <h3>GIS</h3>
                    <p>Developing Android apps for data collection, spatial analysis, and map visualization in
                        Geographic Information Systems.</p>
                </div>
            </div>
        </div>
    </div>
</section>