<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Node js</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>NODE JS DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>From crafting robust web applications using Node.js to developing sleek mobile solutions with
                        frameworks like React Native, our services are tailored to tackle intricate business obstacles
                        and propel digital evolution. We specialize in harnessing cloud capabilities through platforms
                        like AWS or Google Cloud to ensure scalability and efficiency in every project. Whether it's
                        architecting scalable backend systems or creating dynamic frontend experiences, we're dedicated
                        to empowering your journey towards digital transformation. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified Node js specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>45+ </h2>
                            <p>Node js projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30 +</h2>
                            <p>qualified Node js specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with Node js development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Node.js development process </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/requirement.svg" alt="image"> </div>
                    <h3>Requirement Analysis</h3>
                    <p>Analyze project requirements, considering Node.js's nature. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/environment.svg" alt="image"> </div>
                    <h3>Environment Setup</h3>
                    <p>Configure Node.js and npm, and install necessary tools.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/structure-design.svg" alt="image"> </div>
                    <h3>Project Structure Design</h3>
                    <p> Design a scalable and modular folder structure.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/modules.svg" alt="image"> </div>
                    <h3>Module Selection</h3>
                    <p>Choose npm modules for efficiency and reliability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/coding.svg" alt="image"> </div>
                    <h3>Coding</h3>
                    <p>Employ asynchronous programming for efficient request handling.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/testing.svg" alt="image"> </div>
                    <h3>Testing</h3>
                    <p> Implement tests using Mocha or Jest for comprehensive testing.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/error-handling.svg" alt="image"> </div>
                    <h3>Error Handling</h3>
                    <p>Implement robust error handling with informative logging.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/considerations.svg" alt="image"> </div>
                    <h3>Security Considerations</h3>
                    <p>Address security concerns with input validation and data encryption.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/deployment.svg" alt="image"> </div>
                    <h3>Deployment</h3>
                    <p> Deploy using PM2 or Docker, ensuring scalability and reliability.</p>
                </div>
            </div>
        </div>
    </div>
</section>