import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FrontendDevlopmentComponent } from './components/pages/frontend-devlopment/frontend-devlopment.component';
import { BackendDevelopmentComponent } from './components/pages/backend-development/backend-development.component';
import { AppDevelopmentComponent } from './components/pages/app-development/app-development.component';
import { BlockchainComponent } from './components/pages/blockchain/blockchain.component';
import { CarrersComponent } from './components/pages/carrers/carrers.component';
import { GraphicDesignComponent } from './components/pages/graphic-design/graphic-design.component';
import { ECommerceComponent } from './components/pages/e-commerce/e-commerce.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { HtmlComponent } from './components/pages/html/html.component';
import { CssComponent } from './components/pages/css/css.component';
import { JavascriptComponent } from './components/pages/javascript/javascript.component';
import { NextJsComponent } from './components/pages/next-js/next-js.component';
import { NodeJsComponent } from './components/pages/node-js/node-js.component';
import { PythonComponent } from './components/pages/python/python.component';
import { SwiftComponent } from './components/pages/swift/swift.component';
import { JavaComponent } from './components/pages/java/java.component';
import { CPlusComponent } from './components/pages/c-plus/c-plus.component';
import { AndroidComponent } from './components/pages/android/android.component';
import { AwsComponent } from './components/pages/aws/aws.component';
import { AzureComponent } from './components/pages/azure/azure.component';
import { ReactNativeComponent } from './components/pages/react-native/react-native.component';
import { ReactJsComponent } from './components/pages/react-js/react-js.component';
import { AngularComponent } from './components/pages/angular/angular.component';
import { DotNetComponent } from './components/pages/dot-net/dot-net.component';
import { CSharpComponent } from './components/pages/c-sharp/c-sharp.component';
import { ExpressComponent } from './components/pages/express/express.component';
import { KotlinComponent } from './components/pages/kotlin/kotlin.component';
import { PortfolioDetailComponent } from './components/pages/projects-two/portfolio-detail/portfolio-detail.component';

const routes: Routes = [
    { path: "", component: HomeFourComponent },
    { path: "about", component: AboutComponent },
    { path: "portfolio", component: ProjectsTwoComponent },
    { path: "portfolio-detail", component: PortfolioDetailComponent },
    { path: "error", component: ErrorComponent },
    // { path: "blog", component: BlogRightSidebarComponent },
    { path: "contact-us", component: ContactComponent },
    { path: "frontend-development", component: FrontendDevlopmentComponent },
    { path: "backend-development", component: BackendDevelopmentComponent },
    { path: "app-development", component: AppDevelopmentComponent },
    { path: "blockchain", component: BlockchainComponent },
    { path: "carrers", component: CarrersComponent },
    { path: "graphic-design", component: GraphicDesignComponent },
    { path: "e-commerce-solution", component: ECommerceComponent },
    { path: "terms-and-conditions", component: TermsAndConditionsComponent },
    { path: "privacy-policy", component: PrivacyPolicyComponent },
    { path: "html", component: HtmlComponent },
    { path: "css", component: CssComponent },
    { path: "javascript", component: JavascriptComponent },
    { path: "react-js", component: ReactJsComponent },
    { path: "next-js", component: NextJsComponent },
    { path: "angular", component: AngularComponent },
    { path: "dot-net", component: DotNetComponent },
    { path: "node-js", component: NodeJsComponent },
    { path: "python", component: PythonComponent },
    { path: "react-native", component: ReactNativeComponent },
    { path: "swift", component: SwiftComponent },
    { path: "java", component: JavaComponent },
    { path: "c-sharp", component: CSharpComponent },
    { path: "c-plus", component: CPlusComponent },
    { path: "express", component: ExpressComponent },
    { path: "android", component: AndroidComponent },
    { path: "kotlin", component: KotlinComponent },
    { path: "azure", component: AzureComponent },
    { path: "aws", component: AwsComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
