<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>C Sharp</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>C SHARP DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>From architecting robust web applications using C# to crafting polished mobile solutions with
                        frameworks like Xamarin, our services are meticulously tailored to conquer complex business
                        challenges and drive digital progress. With a dedicated emphasis on leveraging cloud
                        technologies such as Azure or AWS, we guarantee scalability and efficiency in every project.
                        Whether it involves designing resilient backend architectures or sculpting engaging frontend
                        experiences, we are steadfast in our commitment to guiding your journey towards digital
                        transformation with expertise and innovation.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified C Sharp specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>45+ </h2>
                            <p>C Sharp projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30 +</h2>
                            <p>qualified C Sharp specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with C Sharp development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>C Sharp development process </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/requirement.svg" alt="image"> </div>
                    <h3>Requirement Analysis</h3>
                    <p>Thoroughly analyze project requirements, considering Node.js's asynchronous nature. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/environment.svg" alt="image"> </div>
                    <h3>Environment Setup </h3>
                    <p>Install Visual Studio or Visual Studio Code, and configure the development environment.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/project-st.svg" alt="image"> </div>
                    <h3>Project Structure </h3>
                    <p>Organize the project into logical components and layers for maintainability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/framework.svg" alt="image"> </div>
                    <h3>Framework Selection </h3>
                    <p>Choose the appropriate .NET Core, .NET Framework, or .NET 5 based on project needs.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/coding.svg" alt="image"> </div>
                    <h3>Coding</h3>
                    <p>Write clean, object-oriented code following C# best practices and SOLID principles.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/testing.svg" alt="image"> </div>
                    <h3>Testing</h3>
                    <p>Implement unit tests using frameworks like NUnit or MSTest for robust code validation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/debugging.svg" alt="image"> </div>
                    <h3>Debugging</h3>
                    <p>Use debugging tools in Visual Studio to identify and resolve issues effectively.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/optimization.svg" alt="image"> </div>
                    <h3>Optimization</h3>
                    <p>Optimize code for performance and memory usage, asynchronous programming when necessary.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/documentation.svg" alt="image"> </div>
                    <h3>Documentation</h3>
                    <p>Document code thoroughly to aid future maintenance and collaboration.</p>
                </div>
            </div>
        </div>
    </div>
</section>