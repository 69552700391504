import { Component } from '@angular/core';

@Component({
  selector: 'app-frontend-devlopment',
  templateUrl: './frontend-devlopment.component.html',
  styleUrl: './frontend-devlopment.component.scss'
})
export class FrontendDevlopmentComponent {

}
