import { Component } from '@angular/core';

@Component({
  selector: 'app-aws',
  templateUrl: './aws.component.html',
  styleUrl: './aws.component.scss'
})
export class AwsComponent {

}
