import { Component } from '@angular/core';

@Component({
  selector: 'app-react-native',
  templateUrl: './react-native.component.html',
  styleUrl: './react-native.component.scss'
})
export class ReactNativeComponent {

}
