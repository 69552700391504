import { Component } from '@angular/core';

@Component({
  selector: 'app-swift',
  templateUrl: './swift.component.html',
  styleUrl: './swift.component.scss'
})
export class SwiftComponent {

}
