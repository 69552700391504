import { Component } from '@angular/core';

@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrl: './html.component.scss'
})
export class HtmlComponent {

}
