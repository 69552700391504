<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blockchain</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-details-area pt-100 protfolio-section bg-color">
    <div class="container">
        <div class="section-title">
            <h2>Blockchain infrastructure powering secure,</h2>
            <h2>decentralized innovation.</h2>
            <div class="bar"></div>
            <p> We provide all the tools and resources builders need to create incredible products — all backed by
                unparalleled, globally-balanced infrastructure, guaranteed reliability and security, a user-friendly
                interface, and end-to-end customer support. </p>
            <h3>Our Custom Solutions</h3>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/ico-development.png" alt="image"> </div>
                    <h3>ICO Development</h3>
                    <p>Any company, but particularly startups, will need a sizeable number of money to launch their
                        endeavour and gain a presence in the market. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/dapp-development.png" alt="image"> </div>
                    <h3>Dapp Development </h3>
                    <p>Dapp development involves creating decentralized applications that utilize blockchain technology
                        to provide secure and transparent functionalities. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/nft-development.png" alt="image"> </div>
                    <h3> NFT Development </h3>
                    <p>NFT development encompasses the creation of distinctive digital assets utilizing blockchain
                        technology, commonly utilized in the realms of art, collectibles, and digital ownership. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/offering.png" alt="image"> </div>
                    <h3> Coin/Token Offering </h3>
                    <p> Our team is unfailingly there for you throughout the time to market your coins/tokens with best
                        ico/sto/uto services. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/altcoin.png" alt="image"> </div>
                    <h3> Altcoin Development</h3>
                    <p> We build latest altcoins to allow seamless transactions between the traders from anywhere
                        instantly. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/hyperledger.png" alt="image"> </div>
                    <h3>Hyperledger</h3>
                    <p>Hyperledger is an open-source collaborative effort created to advance cross-industry blockchain
                        technologies. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-details-area ptb-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 services-details">
                <div class="services-details-desc">
                    <h3>Purchase Process</h3>
                    <p>Stablecoins are created to stabilize the credit and maintain a fixed value corresponding to an
                        underlying asset or a product. But creating a stablecoin consumes a lot of time and more of
                        technical procedures. </p>
                    <p> You have to submit your KYC and AML verifications, work on wiring of funds to bring out the
                        proportional stablecoin.Our expert developers help you create customizable stablecoin tokens
                        that are compatible with your desired exchanges to support the trading. </p>
                </div>
            </div>
            <div class="col-lg-6 services-details-image bg-color justify-content-center d-flex"> <img
                    src="assets/img/blockchain/diagram.png" class="animate__animated animate__fadeInUp" alt="image">
            </div>
        </div>
    </div>
</div>
<div class="services-details-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Blockchain Industry</h2>
            <div class="bar"></div>
            <p>The blockchain industry is revolutionizing sectors across finance, supply chain management, and beyond
                with its decentralized and immutable ledger technology.</p>
        </div>
        <section>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card">
                        <div class="cover item-a">
                            <h2>Healthcare</h2>
                            <div class="card-back"> <a>Access to affordable healthcare is crucial for ensuring the
                                    well-being of individuals and communities</a> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card">
                        <div class="cover item-b">
                            <h2>Finance</h2>
                            <div class="card-back"> <a>Finance is the management of money and assets to achieve
                                    financial goals and maximize returns.</a> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card">
                        <div class="cover item-c">
                            <h2>Insurance</h2>
                            <div class="card-back"> <a> Insurance provides financial protection against unforeseen
                                    events or losses by transferring the risk to an insurance company in exchange for
                                    regular premium payments.</a> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="card">
                        <div class="cover item-d">
                            <h2>Supply Chain</h2>
                            <div class="card-back"> <a>Effective supply chain management is essential for optimizing
                                    efficiency and minimizing costs throughout the production and distribution
                                    process.</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<section class="services-details-area pt-100 protfolio-section bg-color">
    <div class="container">
        <div class="section-title">
            <h2>Our Crypto Coin Development Expertise </h2>
            <div>
                <div class=" pt-4 d-flex">
                    <div class="icon"> <img src="assets/img/blockchain/bitcoin.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/ethereum.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/litecoin.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/dash.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/monero.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/bitcoin-cash.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/ripple.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/nem.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/neo.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/nano.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/zcash.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/nxt.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/dogecoin.webp" alt="image"> </div>
                    <div class="icon"> <img src="assets/img/blockchain/waves.webp" alt="image"> </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/crypto.png" alt="image"> </div>
                    <h3>Crypto Tokens </h3>
                    <p>Cryptocurrency tokens are digital assets traded on blockchain networks, representing value or
                        utility within decentralized ecosystems.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/asset-blockchain.png" alt="image"> </div>
                    <h3><a>Asset Token </a></h3>
                    <p>An asset token represents ownership or entitlement to a specific asset, typically digitized and
                        tradable on blockchain platforms. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 ">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/utility.png" alt="image"> </div>
                    <h3>Utility Token </h3>
                    <p class="">A utility token is a digital currency used to access specific products or services
                        within a particular platform or ecosystem.
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/secuity.png" alt="image"> </div>
                    <h3>Security Token </h3>
                    <p>A security token is a digital asset that represents ownership in a real-world asset, typically
                        subject to regulatory compliance.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/equity.png" alt="image"> </div>
                    <h3>Equity Token </h3>
                    <p>Equity tokens, utilizing blockchain technology, offer fractional ownership and enhance liquidity
                        in asset transactions. </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area">
    <div class="container">
        <div class="col"> <img src="assets/img/blockchain/diagram-flow.webp" alt="diagram"> </div>
    </div>
</section>
<section class="services-details-area pt-100 protfolio-section bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/eto.png" alt="image"> </div>
                    <h3>STO/UTO/ETO Development</h3>
                    <p> Robust sto/uto/eto development that will help you enhance the reach and the profits of your
                        business in high speed. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/metaverse.png" alt="image"> </div>
                    <h3> <a>Metaverse Token Development </a> </h3>
                    <p> Get the trending and successful metaverse token development to easily and quickly power up in
                        the marketplace. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/Asset.png" alt="image"> </div>
                    <h3>Asset Backed Token</h3>
                    <p> Enable asset backed token to your business to have an immutable, secured, transparent and
                        traceable transactions. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/telegram-bot.png" alt="image"> </div>
                    <h3><a>Telegram Bot Token </a></h3>
                    <p> Get intelligent telegram bot token open to further developments and advancements for easy
                        customization. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/NFT.png" alt="image"> </div>
                    <h3> <a>NFT Development </a> </h3>
                    <p> Impressive non fungible token development solutions for artwork, famous video clips, memes,
                        tweets etc. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/blockchain/defi-token.png" alt="image"> </div>
                    <h3> <a>DeFi Token Development </a> </h3>
                    <p> Create a highly safe and secured decentralized finance tokens to hold the assets and facilitate
                        easy transactions. </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area">
    <div class="container">
        <div class="col"> <img src="assets/img/blockchain/Core-Blockchain-Developer.png" alt="diagram"> </div>
    </div>
</section>