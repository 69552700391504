<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>AWS</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>AWS Overview </h3>
                    <div class="bar"></div>
                    <p>AWS, or Amazon Web Services, is a leading cloud computing platform offering a wide array of
                        scalable and cost-effective cloud services. With features like reliability, security,
                        flexibility, and seamless integration, AWS empowers businesses to innovate and grow while
                        optimizing costs. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12"> <img src="assets/img/icon/aws.png" alt="image"> </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Key Features of Amazon Web Services (AWS) </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/scalability.svg" alt="image"> </div>
                    <h3>Scalability</h3>
                    <p>AWS offers scalable computing power, storage, and databases, allowing businesses to easily scale
                        resources up or down based on demand.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/reliability.svg" alt="image"> </div>
                    <h3>Reliability </h3>
                    <p>With multiple data centers across the globe, AWS provides high availability and fault tolerance,
                        ensuring continuous operation of applications and services.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/security.svg" alt="image"> </div>
                    <h3>Security</h3>
                    <p>Rigorous encryption, identity management, and compliance certifications ensure top-tier
                        protection against cyber threats and unauthorized access. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/flexibility.svg" alt="image"> </div>
                    <h3>Flexibility</h3>
                    <p>AWS provides diverse services such as computing, storage, networking, machine learning,
                        analytics, and IoT, enabling businesses to customize solutions to their specific needs. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/cost-effectiveness.svg" alt="image"> </div>
                    <h3>Cost-effectiveness</h3>
                    <p> With AWS's pay-as-you-go model, businesses incur expenses only for utilized resources,
                        minimizing upfront investments and enabling flexible budget allocation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/integration.svg" alt="image"> </div>
                    <h3>Integration</h3>
                    <p>AWS integrates seamlessly with a range of AWS services and third-party tools, simplifying
                        application migration and enabling innovative solutions. </p>
                </div>
            </div>
        </div>
    </div>
</section>