import { Component } from '@angular/core';

@Component({
  selector: 'app-c-sharp',
  templateUrl: './c-sharp.component.html',
  styleUrl: './c-sharp.component.scss'
})
export class CSharpComponent {

}
