<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>ASP.NET</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>.NET DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>From building enterprise-grade web applications with .NET and mobile solutions with Xamarin to
                        leveraging cloud capabilities with Azure, our services are designed to address complex business
                        challenges and drive digital transformation. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified ASP.NET specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>45+ </h2>
                            <p>ASP.NET projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30 +</h2>
                            <p>qualified ASP.NET specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with ASP.NET development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>WHY CHOOSE KAUTILYAM FOR ASP.NET DEVELOPMENT </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/requirement.svg" alt="image"> </div>
                    <h3>Requirement Analysis</h3>
                    <p> Understand and document the project requirements, including features, functionality, and
                        performance expectations.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/design.svg" alt="image"> </div>
                    <h3>Architecture Design</h3>
                    <p> Create a high-level architecture design, outlining the structure of the application, including
                        layers, components, and interactions.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/environment.svg" alt="image"> </div>
                    <h3>Environment Setup</h3>
                    <p>Set up the development environment with the necessary tools and frameworks, .NET SDK, and version
                        control systems like Git.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/coding.svg" alt="image"> </div>
                    <h3>Coding</h3>
                    <p>Write code using .NET languages such as C# or F#, following best practices and design patterns
                        like MVC or MVVM for building scalable and maintainable applications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/testing.svg" alt="image"> </div>
                    <h3>Testing</h3>
                    <p> Perform unit testing, integration testing, and system testing to ensure the quality and
                        reliability of the application, using tools like NUnit or MSTest.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/optimization.svg" alt="image"> </div>
                    <h3>Debugging and Optimization</h3>
                    <p> Identify and fix bugs and performance issues in the codebase, optimizing it for efficiency and
                        scalability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/documentation.svg" alt="image"> </div>
                    <h3>Documentation</h3>
                    <p> Document the codebase, including APIs, classes, and methods, to facilitate understanding and
                        maintenance for other developers.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/deployment.svg" alt="image"> </div>
                    <h3>Deployment</h3>
                    <p>Deploy the application to staging environments, configuring servers, databases, and other
                        infrastructure components as needed.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/update.svg" alt="image"> </div>
                    <h3>Maintenance and Updates</h3>
                    <p>Ensure continuous maintenance and support, feature upgrades, and security patches, to sustain the
                        application's functionality. </p>
                </div>
            </div>
        </div>
    </div>
</section>