export enum PortfolioType {
    HMS = 'HMS',
    ECommerce = 'ECommerce',
    Insurance = 'Insurance',
    DocumentMangement = 'Document Management',
    Business = 'Business',
    DataSecurity = 'Data Security',
    AdminPanel = 'Admin Panel',
  }
  
  export const PortfolioItems = [
    {
      name: 'Delmonte',
      subTitle: 'Delmonte ECommerce',
      type: PortfolioType.ECommerce,
  
      description: [
        "Delmonte's advanced admin panel offers a robust solution for managing operations across multiple locations, catering to both directors and administrators. Admins have access to track customer orders for a specific location, enabling precise control and management of localized operations. On the other hand, directors can oversee the entire company's operations, spanning over 100 warehouse locations, ensuring comprehensive oversight and strategic decision-making.",
        'Key functionalities include monitoring short-shipped orders, processing goods returns, generating credit memos, and handling customer support efficiently. The product listing feature allows admins to manage inventory effectively. Directors have access to more advanced tools, such as product grouping, USDA pricing management, customer agreements, and complex pricing calculations. These features empower directors to maintain consistency and accuracy in pricing across all locations, streamline product management, and enforce customer agreements seamlessly.',
        "Delmonte’s admin panel is designed to enhance operational efficiency, ensure consistent product availability, and provide exceptional customer service. By integrating these critical functions into a single platform, the panel supports both day-to-day management and long-term strategic planning, driving the company's growth and success.",
      ],
      redirectTo: 'https://delmonte-ecommerce.web.app',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'Admin',
          passwordKey: 'Password',
          passwordValue: 'Admin',
        },
        {
          type: 'Director',
          userKey: 'Username',
          userValue: 'Director',
          passwordKey: 'Password',
          passwordValue: 'Director',
        },
      ],
      imageLink: 'img9.png',
      slider: [
        {
          small: 'assets/img/portfolio/delmonte/login-page.png',
          medium: 'assets/img/portfolio/delmonte/login-page.png',
          big: 'assets/img/portfolio/delmonte/login-page.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/director-dash.png',
          medium: 'assets/img/portfolio/delmonte/director-dash.png',
          big: 'assets/img/portfolio/delmonte/director-dash.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/admin-dash.png',
          medium: 'assets/img/portfolio/delmonte/admin-dash.png',
          big: 'assets/img/portfolio/delmonte/admin-dash.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/director-reporting.png',
          medium: 'assets/img/portfolio/delmonte/director-reporting.png',
          big: 'assets/img/portfolio/delmonte/director-reporting.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/pricing-mann.png',
          medium: 'assets/img/portfolio/delmonte/pricing-mann.png',
          big: 'assets/img/portfolio/delmonte/pricing-mann.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/products-avilable.png',
          medium: 'assets/img/portfolio/delmonte/products-avilable.png',
          big: 'assets/img/portfolio/delmonte/products-avilable.png',
        },
        {
          small: 'assets/img/portfolio/delmonte/usd-pricing-per-week.png',
          medium: 'assets/img/portfolio/delmonte/usd-pricing-per-week.png',
          big: 'assets/img/portfolio/delmonte/usd-pricing-per-week.png',
        },
      ],
      technologies: [
        'HTML',
        'CSS',
        'BOOTSTRAP',
        'Tailwind',
        'Angular',
        'Node JS',
      ],
    },
    {
      name: 'Healthcare',
      subTitle: 'Healthcare Management System',
      type: PortfolioType.HMS,
      description: [
        "Safeer Hospital's comprehensive admin panel is designed to streamline hospital operations by providing tailored access to admins, operators, and doctors. This all-in-one platform simplifies the management of critical hospital functions, enhancing efficiency and improving patient care.",
        'The admin panel features a robust patient registration system, ensuring smooth onboarding of new patients. Appointment booking and calendar management, including the availability of specific slots on particular days, allow for effective scheduling, reducing waiting times and optimizing patient flow. The panel also supports the management of emergency patients, enabling swift responses to urgent cases.',
        'Laboratory, radiology, and pharmacy modules are integrated into the system, ensuring seamless coordination between departments and accurate tracking of patient treatments and medications. For admitted patients, the panel offers tools for monitoring patient status and managing bed availability, enhancing care quality.',
        "Billing and document management functionalities ensure accurate financial tracking and secure storage of patient records, while the reporting feature provides valuable insights into hospital operations, aiding in data-driven decision-making. Safeer Hospital’s admin panel is designed to deliver a unified experience that supports staff in delivering exceptional patient care, ensuring the hospital's smooth and efficient operation.",
      ],
      redirectTo: 'https://healthcare-clinic.web.app',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'Admin',
          passwordKey: 'Password',
          passwordValue: 'Admin',
        },
      ],
      imageLink: 'img12.png',
      slider: [
        {
          small: 'assets/img/portfolio/healthcare/login-page.png',
          medium: 'assets/img/portfolio/healthcare/login-page.png',
          big: 'assets/img/portfolio/healthcare/login-page.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/admin-dash.png',
          medium: 'assets/img/portfolio/healthcare/admin-dash.png',
          big: 'assets/img/portfolio/healthcare/admin-dash.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/daily-appointment.png',
          medium: 'assets/img/portfolio/healthcare/daily-appointment.png',
          big: 'assets/img/portfolio/healthcare/daily-appointment.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/weekly-appointment.png',
          medium: 'assets/img/portfolio/healthcare/weekly-appointment.png',
          big: 'assets/img/portfolio/healthcare/weekly-appointment.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/patient-service.png',
          medium: 'assets/img/portfolio/healthcare/patient-service.png',
          big: 'assets/img/portfolio/healthcare/patient-service.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/patient-visit-note.png',
          medium: 'assets/img/portfolio/healthcare/patient-visit-note.png',
          big: 'assets/img/portfolio/healthcare/patient-visit-note.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/billing.png',
          medium: 'assets/img/portfolio/healthcare/billing.png',
          big: 'assets/img/portfolio/healthcare/billing.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/pharmacy-view.png',
          medium: 'assets/img/portfolio/healthcare/pharmacy-view.png',
          big: 'assets/img/portfolio/healthcare/pharmacy-view.png',
        },
        {
          small: 'assets/img/portfolio/healthcare/reporting.png',
          medium: 'assets/img/portfolio/healthcare/reporting.png',
          big: 'assets/img/portfolio/healthcare/reporting.png',
        },
      ],
      technologies: [
        'HTML',
        'CSS',
        'BOOTSTRAP',
        'Tailwind',
        'Angular',
        '.NET Core',
        'MYSQL',
      ],
    },
    {
      name: 'Formoney',
      subTitle: 'Formoney Insurance',
      type: PortfolioType.Insurance,
      description: [
        'For Money is a versatile funds transfer portal designed to simplify financial management for both companies and individuals. The platform offers a seamless experience for users, allowing them to register as individuals, companies, or even multiple companies under one account, providing flexibility for varied business needs.',
        'Key features include the ability to connect multiple bank accounts, offering a consolidated view of balances across all accounts in one place. This allows users to stay on top of their financial status with ease. The portal also supports invoice generation, making it simple to manage receivables and payables, ensuring that all financial transactions are tracked accurately.',
        'For Money also provides detailed cash flow insights, enabling users to monitor their financial health in real-time. The bills-to-pay feature and autopayment functionality further enhance convenience by automating regular payments, reducing the risk of missed deadlines. Whether managing personal finances or complex company accounts, For Money streamlines the entire process, making financial management straightforward and efficient.',
        'This portal is designed to meet the needs of modern businesses and individuals, ensuring that financial operations are handled smoothly and securely, all within a user-friendly interface.',
      ],
      redirectTo: 'https://formoney-bussiness.web.app/',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'demo@formoney.com',
          passwordKey: 'Password',
          passwordValue: 'demo@1234',
        },
      ],
      imageLink: 'img11.png',
      slider: [
        {
          small: 'assets/img/portfolio/formoney/login-page.png',
          medium: 'assets/img/portfolio/formoney/login-page.png',
          big: 'assets/img/portfolio/formoney/login-page.png',
        },
        {
          small: 'assets/img/portfolio/formoney/business-dashboard.png',
          medium: 'assets/img/portfolio/formoney/business-dashboard.png',
          big: 'assets/img/portfolio/formoney/business-dashboard.png',
        },
        {
          small: 'assets/img/portfolio/formoney/business-setting-bank.png',
          medium: 'assets/img/portfolio/formoney/business-setting-bank.png',
          big: 'assets/img/portfolio/formoney/business-setting-bank.png',
        },
        {
          small: 'assets/img/portfolio/formoney/business-setting.png',
          medium: 'assets/img/portfolio/formoney/business-setting.png',
          big: 'assets/img/portfolio/formoney/business-setting.png',
        },
        {
          small: 'assets/img/portfolio/formoney/cash-flow.png',
          medium: 'assets/img/portfolio/formoney/cash-flow.png',
          big: 'assets/img/portfolio/formoney/cash-flow.png',
        },
        {
          small: 'assets/img/portfolio/formoney/invoices.png',
          medium: 'assets/img/portfolio/formoney/invoices.png',
          big: 'assets/img/portfolio/formoney/invoices.png',
        },
        {
          small: 'assets/img/portfolio/formoney/vendors-auto-payments.png',
          medium: 'assets/img/portfolio/formoney/vendors-auto-payments.png',
          big: 'assets/img/portfolio/formoney/vendors-auto-payments.png',
        },
        {
          small: 'assets/img/portfolio/formoney/my-payment-py.png',
          medium: 'assets/img/portfolio/formoney/my-payment-py.png',
          big: 'assets/img/portfolio/formoney/my-payment-py.png',
        },
        {
          small: 'assets/img/portfolio/formoney/my-payment.png',
          medium: 'assets/img/portfolio/formoney/my-payment.png',
          big: 'assets/img/portfolio/formoney/my-payment.png',
        },
      ],
      technologies: [
        'HTML',
        'CSS',
        'BOOTSTRAP',
        'Tailwind',
        'Angular',
        'Node JS',
        'MongoDB',
      ],
    },
    {
      name: 'Avni',
      subTitle: 'CyberShield Admin Panel',
      type: PortfolioType.AdminPanel,
      description: [
        'Avni is a dynamic and customizable admin panel designed to streamline business operations through its advanced and versatile functionalities. It caters to a wide range of organizational needs, providing flexibility, scalability, and ease of use in managing various administrative tasks. Additionally, Avni supports multicolor themes and multilanguage features, ensuring a personalized experience for users across different regions.',
        'One of Avni’s key features is its robust multitenant architecture. This allows tenants to be created at runtime with support for multiple databases, including SQL Server, MySQL, PostgreSQL, Oracle, and SQLite. The platform also offers a unique capability where navigation, settings, and other critical configurations can be transferred from the master admin to any tenant with a single click, simplifying the setup process and ensuring consistency across all tenants.',
        'The platform includes dynamic navigation management, enabling admins to assign custom navigations for different users and roles, ensuring that each user has access to the relevant features and information. This navigation assignment is flexible and can be adapted to meet the needs of different tenants or departments.',
        "Avni’s dashboard offers support for multiple chart types, along with real-time data updates via WebSocket protocol, ensuring users always have access to the latest insights. The system also boasts comprehensive form-building capabilities featuring 22 different form controls, including textboxes, select boxes (with cascading options like city, state, country), multiselect dropdowns, radio buttons, checkboxes, date pickers, and more. Each control is fully customizable with built-in validation and supports advanced server-side filtering for optimized data management. Additionally, tables come with server-side pagination, column filtering, and sorting, and they can be converted to card view when accessed on mobile devices, providing a seamless and intuitive experience across all platforms."
      ],
      redirectTo: 'https://avni-admin.web.app/',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'Master',
          passwordKey: 'Password',
          passwordValue: 'Master@003',
          tenantKey: 'TenantCode',
          tenantValue: 'Master',
        },
      ],
      imageLink: 'master-dashboard.png',
      slider: [
        {
          small: 'assets/img/portfolio/avni/login-page.png',
          medium: 'assets/img/portfolio/avni/login-page.png',
          big: 'assets/img/portfolio/avni/login-page.png',
        },
        {
          small: 'assets/img/portfolio/avni/master-dashboard.png',
          medium: 'assets/img/portfolio/avni/master-dashboard.png',
          big: 'assets/img/portfolio/avni/master-dashboard.png',
        },
        {
          small: 'assets/img/portfolio/avni/form-generation.png',
          medium: 'assets/img/portfolio/avni/form-generation.png',
          big: 'assets/img/portfolio/avni/form-generation.png',
        },
        {
          small: 'assets/img/portfolio/avni/select-cascading-option.png',
          medium: 'assets/img/portfolio/avni/select-cascading-option.png',
          big: 'assets/img/portfolio/avni/select-cascading-option.png',
        },
        {
          small: 'assets/img/portfolio/avni/form-generation-legacy.png',
          medium: 'assets/img/portfolio/avni/form-generation-legacy.png',
          big: 'assets/img/portfolio/avni/form-generation-legacy.png',
        },
        {
          small: 'assets/img/portfolio/avni/table-generation.png',
          medium: 'assets/img/portfolio/avni/table-generation.png',
          big: 'assets/img/portfolio/avni/table-generation.png',
        },
        {
          small: 'assets/img/portfolio/avni/table-generation-legacy.png',
          medium: 'assets/img/portfolio/avni/table-generation-legacy.png',
          big: 'assets/img/portfolio/avni/table-generation-legacy.png',
        },
        {
          small: 'assets/img/portfolio/avni/dashboard-generation.png',
          medium: 'assets/img/portfolio/avni/dashboard-generation.png',
          big: 'assets/img/portfolio/avni/dashboard-generation.png',
        }
      ],
      technologies: ['HTML', 'CSS', 'BOOTSTRAP', 'Angular', '.NET Core', 'MYSQL'],
    },
    {
      name: 'WebQA',
      subTitle: 'WebQA Document Management System',
      type: PortfolioType.DocumentMangement,
      description: [
        'WebQA is a specialized document management portal designed for healthcare companies, offering a structured approach to managing documents and policies across various management levels. This platform is tailored to accommodate the unique needs of healthcare organizations, ensuring that critical information is handled efficiently and securely.',
        'Healthcare companies can register on WebQA with four distinct management levels: standalone, center, upper, and lower management. Each level is crafted to reflect the specific hierarchy and operational needs of the organization. Once these management levels are established, the portal provides comprehensive tools for managing documents and policies, allowing for precise control and oversight at every stage.',
        "By streamlining document management, WebQA helps healthcare organizations maintain compliance, improve workflow efficiency, and ensure that all stakeholders have access to the most up-to-date information. Whether it's for policy management, document storage, or regulatory compliance, WebQA offers a robust solution that supports the complex needs of the healthcare industry.",
      ],
      redirectTo: 'https://webqa-policy.web.app',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'admin',
          passwordKey: 'Password',
          passwordValue: 'admin',
        },
      ],
      imageLink: 'img13.png',
      slider: [
        {
          small: 'assets/img/portfolio/webqa/login-page.png',
          medium: 'assets/img/portfolio/webqa/login-page.png',
          big: 'assets/img/portfolio/webqa/login-page.png',
        },
        {
          small: 'assets/img/portfolio/webqa/hierarchy.png',
          medium: 'assets/img/portfolio/webqa/hierarchy.png',
          big: 'assets/img/portfolio/webqa/hierarchy.png',
        },
        {
          small: 'assets/img/portfolio/webqa/organization-setup.png',
          medium: 'assets/img/portfolio/webqa/organization-setup.png',
          big: 'assets/img/portfolio/webqa/organization-setup.png',
        },
        {
          small: 'assets/img/portfolio/webqa/policies.png',
          medium: 'assets/img/portfolio/webqa/policies.png',
          big: 'assets/img/portfolio/webqa/policies.png',
        },
      ],
      technologies: [
        'HTML',
        'CSS',
        'BOOTSTRAP',
        'Tailwind',
        'Angular',
        '.NET Core',
        'MYSQL',
      ],
    },
    {
      name: 'TrustX',
      subTitle: 'TrustX Business',
      type: PortfolioType.Business,
      description: [
        'TrustX is a dynamic platform designed for the exchange of cryptocurrencies and the management of NFTs, offering a comprehensive suite of features tailored to both users and administrators. Users can easily deposit and withdraw USDT tokens, ensuring smooth transactions within the platform. The platform also offers premium memberships with various packages, catering to different user needs and preferences. Additionally, users can benefit from referral bonuses, which are neatly organized and displayed in a tree view within the user panel, providing clear visibility of their network and earnings.',
        'For administrators, TrustX offers robust tools to oversee and manage platform activities. Admins can monitor all user accounts, including deposits, withdrawals, and premium membership approvals. The platform also provides advanced reporting capabilities, such as tracking P2P transfers, calculating matching bonuses, and monitoring daily ROI per user. Admins can also manage user rewards and access a variety of other reports that ensure the smooth operation of the platform.',
        'TrustX is designed to support the growing needs of the cryptocurrency and NFT communities, offering a secure, user-friendly environment for trading, managing digital assets, and maximizing earnings through strategic features and transparent reporting.',
      ],
      redirectTo: 'https://trustx-crypto.web.app',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'Admin8866',
          passwordKey: 'Password',
          passwordValue: 'Omkar@70005',
        },
        {
          type: 'User',
          userKey: 'Username',
          userValue: 'T134529',
          passwordKey: 'Password',
          passwordValue: 'Jash@8916',
        },
      ],
      imageLink: 'img10.png',
      slider: [
        {
          small: 'assets/img/portfolio/trustx/landing-page.png',
          medium: 'assets/img/portfolio/trustx/landing-page.png',
          big: 'assets/img/portfolio/trustx/landing-page.png',
        },
        {
          small: 'assets/img/portfolio/trustx/login-page.png',
          medium: 'assets/img/portfolio/trustx/login-page.png',
          big: 'assets/img/portfolio/trustx/login-page.png',
        },
        {
          small: 'assets/img/portfolio/trustx/dashboard.png',
          medium: 'assets/img/portfolio/trustx/dashboard.png',
          big: 'assets/img/portfolio/trustx/dashboard.png',
        },
        {
          small: 'assets/img/portfolio/trustx/admin-dash.png',
          medium: 'assets/img/portfolio/trustx/admin-dash.png',
          big: 'assets/img/portfolio/trustx/admin-dash.png',
        },
        {
          small: 'assets/img/portfolio/trustx/admin-deposite-request.png',
          medium: 'assets/img/portfolio/trustx/admin-deposite-request.png',
          big: 'assets/img/portfolio/trustx/admin-deposite-request.png',
        },
        {
          small: 'assets/img/portfolio/trustx/admin-topup-request.png',
          medium: 'assets/img/portfolio/trustx/admin-topup-request.png',
          big: 'assets/img/portfolio/trustx/admin-topup-request.png',
        },
        {
          small: 'assets/img/portfolio/trustx/binary.png',
          medium: 'assets/img/portfolio/trustx/binary.png',
          big: 'assets/img/portfolio/trustx/binary.png',
        },
        {
          small: 'assets/img/portfolio/trustx/user-deposite.png',
          medium: 'assets/img/portfolio/trustx/user-deposite.png',
          big: 'assets/img/portfolio/trustx/user-deposite.png',
        },
      ],
      technologies: ['HTML', 'CSS', 'BOOTSTRAP', 'Angular', '.NET Core', 'MYSQL'],
    },
    {
      name: 'CyberShield',
      subTitle: 'CyberShield Data Security',
      type: PortfolioType.DataSecurity,
      description: [
        'Cybershield is an innovative project currently under development, focused on providing robust security functionalities tailored to modern business needs. The platform is designed with a strong emphasis on data protection, ensuring that sensitive information is safeguarded by region, adhering to local regulations and compliance standards.',
        'In its current version, Cybershield connects seamlessly with a wide range of critical applications, including accounting, payments, investments, banking, assets, insurance, budgeting, and emergency funds management. This integration allows businesses to manage their operations securely and efficiently within a unified platform.',
        'Cybershield also offers extensive reporting capabilities, enabling users to generate detailed reports on sales, analytical data, HR metrics, insights, inventory, profit, operations, and performance. These reports are further enhanced by the ability to view data segmented by country, providing valuable insights into regional performance and trends.',
      ],
      redirectTo: 'https://cybershield-security.web.app',
      credentials: [
        {
          type: 'Admin',
          userKey: 'Username',
          userValue: 'Admin',
          passwordKey: 'Password',
          passwordValue: 'Admin',
        },
      ],
      imageLink: 'img14.png',
      slider: [
        {
          small: 'assets/img/portfolio/cybershield/login-page.png',
          medium: 'assets/img/portfolio/cybershield/login-page.png',
          big: 'assets/img/portfolio/cybershield/login-page.png',
        },
        {
          small: 'assets/img/portfolio/cybershield/dashboard.png',
          medium: 'assets/img/portfolio/cybershield/dashboard.png',
          big: 'assets/img/portfolio/cybershield/dashboard.png',
        },
        {
          small: 'assets/img/portfolio/cybershield/applications.png',
          medium: 'assets/img/portfolio/cybershield/applications.png',
          big: 'assets/img/portfolio/cybershield/applications.png',
        },
        {
          small: 'assets/img/portfolio/cybershield/region-map.png',
          medium: 'assets/img/portfolio/cybershield/region-map.png',
          big: 'assets/img/portfolio/cybershield/region-map.png',
        },
        {
          small: 'assets/img/portfolio/cybershield/reporting.png',
          medium: 'assets/img/portfolio/cybershield/reporting.png',
          big: 'assets/img/portfolio/cybershield/reporting.png',
        }
      ],
      technologies: ['HTML', 'CSS', 'BOOTSTRAP', 'Tailwind'],
    },
  ];
  