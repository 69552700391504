import { Component } from '@angular/core';

@Component({
  selector: 'app-css',
  templateUrl: './css.component.html',
  styleUrl: './css.component.scss'
})
export class CssComponent {

}
