<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Swift</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>Swift Development Services to Cater Your Needs</h3>
                    <div class="bar"></div>
                    <p>With Swift, we craft iOS applications that are not just functional but also elegant and
                        user-friendly. Our team of experienced developers specializes in leveraging Swift's modern
                        language features to build high-performance mobile applications that run seamlessly on Apple
                        devices.</p>
                    <p>From startups to enterprises, we cater to clients of all sizes and industries. Whether you're
                        looking to create a consumer-facing app, an enterprise solution, or a productivity tool, our
                        Swift development services are tailored to suit your specific needs and goals. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>20 +</h2>
                            <p>qualified Swift specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>40 +</h2>
                            <p>Swift projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with Swift development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Swift Project Requirement Analysis </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/development-cycle.svg"> </div>
                    <h3>Development of Swift Applications</h3>
                    <p>Create robust iOS applications using the Swift programming language, ensuring superior
                        performance and user experience.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/design.svg"> </div>
                    <h3>Designing UI/UX for <br> Swift</h3>
                    <p>Design elegant and user-friendly interfaces for iOS applications based on Swift, ensuring
                        seamless navigation and user engagement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/integration.svg"> </div>
                    <h3>Integration Services for Swift</h3>
                    <p>Effortlessly integrate APIs and backend systems, enabling advanced functionalities such as social
                        integration and cloud services.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/performance.svg"> </div>
                    <h3>Optimizing Swift Performance</h3>
                    <p>Enhance the speed and efficiency of your Swift applications, resulting in an improved user
                        experience with enhanced responsiveness.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/developer-productivity.svg"> </div>
                    <h3>Development of Swift Plugins</h3>
                    <p>Augment Swift applications with custom plugins and frameworks, harnessing Swift's prowess for
                        boosted functionality. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/maintain.svg"> </div>
                    <h3>Maintenance and Support for Swift</h3>
                    <p>Ensure Swift app success with continuous maintenance, promptly addressing bugs and performance
                        for smooth operation. </p>
                </div>
            </div>
        </div>
    </div>
</section>