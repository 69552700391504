<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Frontend Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-details-area pt-100">
    <div class="container">
        <div class="services-details-video">
            <div class="text">
                <h3>Why Choose Kautilyam for Web Development</h3>
                <p>At Kautilyam, client satisfaction is paramount. We are committed to delivering exceptional web-based
                    products of the highest quality, ensuring reliability, security, and optimal performance. Our web
                    development team is carefully handpicked to meet your project's unique requirements, bringing
                    valuable skills and expertise to build your product.</p>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Crafting digital experiences that captivate, one line of code at a time</h3>
                        <p>Frontend development is more than just coding; it's about sculpting the digital landscape
                            with precision and creativity. Each line of code shapes the user experience, making it
                            engaging, intuitive, and visually stunning. By seamlessly blending design with
                            functionality, frontend developers breathe life into websites and applications, creating
                            immersive journeys for users to explore.</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/frontend.jpg"
                            alt="image"> </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area pt-80">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/frontend-2.jpg"
                            alt="image"> </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <div class="features-text">
                            <h4>sculpting digital landscapes with precision and purpose.</h4>
                            <p>In the realm of digital creation, frontend development stands as the meticulous
                                architect, sculpting virtual landscapes with precision and purpose.</p>
                        </div>
                        <div class="features-text">
                            <h4>the art of crafting digital experiences that leave a lasting impression.</h4>
                            <p>Frontend development is more than just building websites and applications; it's about
                                creating memorable experiences that resonate with users long after they've clicked away.
                                It's about blending creativity with technical expertise to design interfaces that are
                                both visually stunning and highly functional.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/angular.svg" alt="image"> </div>
                    <h3>Angular</h3>
                    <p> Angular excels in enhancing your web application's functionality and elevating user experience
                        through substantial extensions.</p> <a class="read-btn" href="/angular">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/laravel.png" alt="image"> </div>
                    <h3>Next js</h3>
                    <p>Next.js is the premier React framework for creating effortlessly optimized server-rendered web
                        applications. </p> <a class="read-btn" href="/next-js">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/html.svg" alt="image"> </div>
                    <h3>HTML</h3>
                    <p> HTML stands for Hypertext Markup Language, which is the standard markup language for creating
                        web pages.</p> <a class="read-btn" href="/html">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/css.svg" alt="image"> </div>
                    <h3>CSS</h3>
                    <p> CSS stands for Cascading Style Sheets, a language used for describing the presentation of a
                        document written in HTML or XML.</p> <a class="read-btn" href="/css">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/js.svg" alt="image"> </div>
                    <h3>Javascript</h3>
                    <p> JavaScript is a versatile scripting language primarily used for client-side web development and
                        interactive website functionalities.</p> <a class="read-btn" href="/javascript">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/react.svg" alt="image"> </div>
                    <h3>React js</h3>
                    <p>React allows developers to create interactive and dynamic web applications by efficiently
                        managing the UI components and their state.</p> <a class="read-btn" href="/react-js">Read
                        More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/bootsrap.svg" alt="image"> </div>
                    <h3>Bootstrap</h3>
                    <p>Bootstrap is a popular front-end framework used for developing responsive and mobile-first
                        websites and web applications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/jquery.svg" alt="image"> </div>
                    <h3>jQuery</h3>
                    <p>jQuery simplifies web development tasks in js such as HTML document traversal, event handling,
                        animation, and Ajax interactions.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/ts.svg" alt="image"> </div>
                    <h3>TypeScript </h3>
                    <p>Utilized TypeScript to construct a resilient backend infrastructure, bolstering type safety and
                        streamlining developer efficiency.</p> <!-- <a class="read-btn" href="/html">Read More</a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<div class="container">
    <div class="section-title">
        <h1>Our Development Process</h1>
        <div class="bar"></div>
    </div>
    <div id="timeline">
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>01 : PLANNING & RESEARCH</h4>
                <ul>
                    <li>Define project goals, target audience, and objectives. </li>
                    <li>Gather requirements, create a timeline.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>02 : DESIGN & CONCEPTUALIZATION </h4>
                <ul>
                    <li>Develop initial design, wireframes for layout and user interface. </li>
                    <li>Design UI/UX, create visual elements, ensure responsive design. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>03 : DEVELOPMENT</h4>
                <ul>
                    <li>Front-End: Code in HTML, CSS, JavaScript, ensure cross-browser compatibility.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>04 : TESTING & DEPLOYMENT</h4>
                <ul>
                    <li>Conduct functional, usability, and security testing; fix bugs. </li>
                    <li>Choose hosting, upload web files, configure server and DNS. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>05 : POST-LAUNCH MANAGEMENT </h4>
                <ul>
                    <li>Conduct live server testing, monitor performance. </li>
                    <li>Regular updates, performance monitoring, user feedback incorporation.</li>
                    <li>Implement marketing strategies, use analytics for insights, adjust as needed.</li>
                    <li>Plan for scaling, ensure regular backups, and prioritize security. </li>
                </ul>
            </div>
        </div>
    </div>
</div>