import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { Subscription, filter } from 'rxjs';
import { PortfolioItems } from '../portfolio.items';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrl: './portfolio-detail.component.scss'
})
export class PortfolioDetailComponent {
  public portfolioItem!: any;
  private routeSubscription!: Subscription;
  public galleryOptions!: NgxGalleryOptions[];
  public galleryImages!: NgxGalleryImage[];
  constructor(private router: Router) {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let portfolioItem: any =
          this.router.getCurrentNavigation()?.extras.state;
        if (portfolioItem) {
          this.portfolioItem = PortfolioItems.find(
            (d) => d.type === portfolioItem.item
          );
        } else {
          this.router.navigate(['']);
        }
      });
  }
  ngOnInit(): void {
    this.galleryOptions = [
      {
        imageSwipe: true,
        width: '100%',
        height: '540px',
        thumbnailsColumns: 4,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide,
        previewCloseOnEsc: true,
        previewKeyboardNavigation: true,
        previewAnimation: false,
        previewCloseOnClick: true,
        previewSwipe: true,
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
