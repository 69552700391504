<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Azure</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>Azure Overview </h3>
                    <div class="bar"></div>
                    <p>Azure, or Amazon Web Services, is a leading cloud computing platform offering a wide array of
                        scalable and cost-effective cloud services. With features like reliability, security,
                        flexibility, and seamless integration, AWS empowers businesses to innovate and grow while
                        optimizing costs. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12"> <img src="assets/img/icon/azure.png" alt="image"> </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Key Features of Amazon Web Services (AWS) </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/scalability.svg" alt="image"> </div>
                    <h3>Scalability</h3>
                    <p>Azure offers scalable computing resources for flexible business growth.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/global.svg" alt="image"> </div>
                    <h3>Global Reach</h3>
                    <p> With data centers worldwide, Azure ensures global coverage and low-latency connectivity.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/security.svg" alt="image"> </div>
                    <h3>Security</h3>
                    <p> Azure implements robust security measures to protect data and applications from cyber threats.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/capabilities.svg" alt="image"> </div>
                    <h3>Hybrid Capabilities</h3>
                    <p> Azure supports seamless integration of on-premises infrastructure with cloud services. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/machine-learning.svg" alt="image"> </div>
                    <h3>AI and Machine Learning</h3>
                    <p> Azure provides powerful AI and machine learning services for intelligent applications. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/developer-productivity.svg" alt="image"> </div>
                    <h3>Developer Productivity</h3>
                    <p> Azure offers developer tools and services to streamline application development processes. </p>
                </div>
            </div>
        </div>
    </div>
</section>