<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>React Native</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>REACT NATIVE DEVELOPMENT SERVICES </h3>
                    <div class="bar"></div>
                    <p>Transitioning from native frameworks to React Native, developing e-commerce solutions, or
                        augmenting your team with our expertise, we’re here to guide you through every step of your
                        mobile app journey. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>20 +</h2>
                            <p>qualified React Native specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>40 +</h2>
                            <p>React Native projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with React Native development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>BENEFITS OF REACT NATIVE DEVELOPMENT </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/unified-development.svg" alt="image"> </div>
                    <h3>Unified <br>Development </h3>
                    <p>Develop for both iOS and Android using a single codebase with React Native, expanding your reach
                        and simplifying the development process. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/development-cycle.svg" alt="image"> </div>
                    <h3>Accelerated Development Cycle </h3>
                    <p>Leverage React Native’s hot reload and other features to speed up development by up to 50%,
                        reducing time spent on recompilations. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/reduce.svg" alt="image"> </div>
                    <h3>Reduce Development Costs</h3>
                    <p>Save on app development expenses by utilizing React Native’s reusable components, streamlining
                        the creation process. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/cut.svg" alt="image"> </div>
                    <h3>Cut Maintenance <br> Cost </h3>
                    <p>Efficiently maintain your app with a single team, cutting down on the costs associated with
                        separate iOS and Android development teams. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/performance.svg" alt="image"> </div>
                    <h3>Enhanced App Performance </h3>
                    <p>Achieve superior app performance with React Native’s ability to integrate native code and render
                        using native APIs, ensuring a user experience. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/mobile-solution.svg" alt="image"> </div>
                    <h3>Future-Proof Mobile Solutions </h3>
                    <p>Future-proof your app with React Native's backward-compatible technology for sustained viability
                        and robustness. </p>
                </div>
            </div>
        </div>
    </div>
</section>