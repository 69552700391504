import {
    Component,
    ElementRef,
    AfterViewInit,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";

@Component({
    selector: "app-navbar-one",
    templateUrl: "./navbar-one.component.html",
    styleUrls: ["./navbar-one.component.scss"],
})
export class NavbarOneComponent implements AfterViewInit {
    @ViewChild("sidebarElement") sidebarElement!: ElementRef;

    constructor(
        private renderer: Renderer2,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngAfterViewInit(): void {
        this.breakpointObserver
            .observe(["(max-width: 991px) and (min-width: 768px)"])
            .subscribe(() => {
                this.toggleSidebar();
            });
    }

    isfrontend: boolean = false;
    isBackend: boolean = false;
    isApp: boolean = false;
    isPlatform: boolean = false;
    isNavbarOpen: boolean = false;
    isTech: boolean = false;
    isCollapsed: boolean = false;

    toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
    }

    toggleTechnologies() {
        this.isTech = !this.isTech;
    }

    frontend() {
        this.isfrontend = !this.isfrontend;
    }

    backend() {
        this.isBackend = !this.isBackend;
    }

    app() {
        this.isApp = !this.isApp;
    }

    platforms() {
        this.isPlatform = !this.isPlatform;
    }

    toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
        if (this.sidebarElement) {
            if (this.isCollapsed) {
                this.renderer.addClass(
                    this.sidebarElement.nativeElement,
                    "navbar-collapse"
                );
            } else {
                this.renderer.removeClass(
                    this.sidebarElement.nativeElement,
                    "navbar-collapse"
                );
            }
        } else {
            console.error("Sidebar element is not available");
        }
    }
}
