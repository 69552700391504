<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Backend Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-details-area pt-100">
    <div class="container">
        <div class="services-details-video">
            <div class="text">
                <h3>Why Choose Kautilyam for Backend Development</h3>
                <p>Kautilyam specializes in backend development, delivering tailored solutions with scalability and
                    efficiency at the forefront. With a focus on security and performance, we ensure your project meets
                    the highest standards of quality and reliability.</p>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Building a Dynamic Backend for Web Applications</h3>
                        <p>This project focuses on the development of a robust backend system for web applications. The
                            backend serves as the backbone of the application, handling data storage, retrieval, and
                            processing, as well as managing user authentication and authorization. The project involves
                            implementing server-side logic using languages such as Python, Node.js, or Ruby on Rails,
                            and utilizing frameworks like Django, Express.js, or Flask. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/backend.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area pt-80">
    <div class="container">
        <div class="services-details-video"> </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/backend-2.jpg"
                            alt="image"> </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <div class="features-text">
                            <h4>Database Management</h4>
                            <p>Designing and implementing a database schema using SQL or NoSQL databases like MySQL,
                                PostgreSQL, MongoDB, or Firebase.</p>
                        </div>
                        <div class="features-text">
                            <h4>API Development: </h4>
                            <p> Creating RESTful or GraphQL APIs to expose functionalities to the frontend, allowing
                                seamless communication between the frontend and backend.</p>
                        </div>
                        <div class="features-text">
                            <h4>Documentation: </h4>
                            <p> Documenting the backend architecture, APIs, and codebase to facilitate future
                                maintenance and collaboration among team members.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/dot-net.png" alt="image"> </div>
                    <h3>Asp.NET</h3>
                    <p>Building a robust backend with ASP.NET for high-performance web applications.</p> <a
                        class="read-btn" href="/dot-net">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/node-js.svg" alt="image"> </div>
                    <h3>Node js</h3>
                    <p>Utilizing Node.js to build a scalable and efficient backend for web applications.</p> <a
                        class="read-btn" href="/node-js">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/python.svg" alt="image"> </div>
                    <h3>Python</h3>
                    <p>Developing a robust backend system using Python to power web applications.</p> <a
                        class="read-btn" href="/python">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/c.svg" alt="image"> </div>
                    <h3>C/C++</h3>
                    <p>C and C++ are foundational languages renowned for their efficiency and versatility in software
                        development.</p> <a class="read-btn" href="/c-plus">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/express.svg" alt="image"> </div>
                    <h3>Express js</h3>
                    <p>Using Express.js, I developed a scalable and efficient backend system for handling data and user
                        authentication in web applications.</p> <a class="read-btn" href="/express">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/csharp.svg" alt="image"> </div>
                    <h3>C Sharp</h3>
                    <p>Developing a robust backend system using PHP for efficient data handling and seamless
                        communication with the frontend.</p> <a class="read-btn" href="/c-sharp">Read More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<div class="container">
    <div class="section-title">
        <h1>Our Development Process</h1>
        <div class="bar"></div>
    </div>
    <div id="timeline">
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>01 : PLANNING & RESEARCH</h4>
                <ul>
                    <li>Define project goals, target audience, and objectives. </li>
                    <li>Gather requirements, create a timeline.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>02 : DESIGN & CONCEPTUALIZATION </h4>
                <ul>
                    <li>Develop initial design, wireframes for layout and user interface. </li>
                    <li>Design UI/UX, create visual elements, ensure responsive design. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>03 : DEVELOPMENT</h4>
                <ul>
                    <li>Back-End: Set up server, database, application logic, and security measures.</li>
                    <li>Database: Design structure, implement data management, optimize performance.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>04 : TESTING & DEPLOYMENT</h4>
                <ul>
                    <li>Conduct functional, usability, and security testing; fix bugs. </li>
                    <li>Choose hosting, upload web files, configure server and DNS. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>05 : POST-LAUNCH MANAGEMENT </h4>
                <ul>
                    <li>Conduct live server testing, monitor performance. </li>
                    <li>Regular updates, performance monitoring, user feedback incorporation.</li>
                    <li>Implement marketing strategies, use analytics for insights, adjust as needed.</li>
                    <li>Plan for scaling, ensure regular backups, and prioritize security. </li>
                </ul>
            </div>
        </div>
    </div>
</div>