<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Java</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>JAVA DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Our Java Development Services encompass a wide array of solutions, tailored to deliver robust,
                        scalable, and high-performing applications. With a strong focus on Java, we offer expertise in
                        building enterprise-grade applications, microservices architectures, and cloud-based solutions.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>20 +</h2>
                            <p>qualified Java specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>40 +</h2>
                            <p>Java projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with Java development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Java Project Requirement Analysis </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/project-objective.svg" alt="image"> </div>
                    <h3>Project Objective Understanding </h3>
                    <p>Thoroughly comprehend the objectives and goals of the Java project.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/client-requirement.svg" alt="image"> </div>
                    <h3>Client Requirement Assessment </h3>
                    <p>Assess client requirements comprehensively for effective project planning and execution. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/scope.svg" alt="image"> </div>
                    <h3>Scope <br> Evaluation </h3>
                    <p>Assess the scope of the project to understand its boundaries and limitations.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/data-analysis.svg" alt="image"> </div>
                    <h3>Functional Requirement Analysis </h3>
                    <p>Examine the functional aspects required to fulfill the project objectives.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/assessment.svg" alt="image"> </div>
                    <h3>Technical Requirement Assessment </h3>
                    <p>Evaluate the technical specifications and prerequisites necessary for project implementation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/resource-identification.svg" alt="image"> </div>
                    <h3>Resource <br>Identification </h3>
                    <p>Identify the resources, tools, and technologies needed to execute the Java project.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/risk-analysis.svg" alt="image"> </div>
                    <h3>Risk Identification </h3>
                    <p>Identify potential risks and challenges that may arise during project development.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/prioritize.svg" alt="image"> </div>
                    <h3>Requirement Prioritization </h3>
                    <p>Prioritize the project requirements based on their importance and urgency.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/stakeholder.svg" alt="image"> </div>
                    <h3>Stakeholder </h3>
                    <p>Ensure clear and effective communication with stakeholders to validate project requirements.</p>
                </div>
            </div>
        </div>
    </div>
</section>