<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>JavaScript</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>JAVASCRIPT DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Unlock the full potential of your web projects with Kautilyam's expert JavaScript development
                        services. Our dedicated team is equipped with the latest tools and technologies to bring your
                        ideas to life, delivering dynamic and interactive web applications tailored to your specific
                        requirements. Whether you need front-end development, back-end integration, or full-stack
                        solutions, we have the expertise to exceed your expectations. From building custom JavaScript
                        components to optimizing performance and scalability, we're committed to delivering high-quality
                        solutions that drive your business forward. Let us empower your projects with innovative
                        JavaScript development. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-check"></i> </div>
                            <h2>Desktop App Development</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-check"></i> </div>
                            <h2>Web App Development </h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-check"></i> </div>
                            <h2>Custom JavaScript App Development</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-check"></i> </div>
                            <h2>Dedicated Development Teams </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Our JavaScript Development Process </h3>
            <p>At any stage of our collaboration, we take care of your business. We know that when it comes to software
                development, there is no one-size-fits-all solution. Our JavaScript development process is a
                comprehensive, yet agile and flexible series of steps: </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/requirement.svg" alt="image"> </div>
                    <h3>Requirement Gathering</h3>
                    <p>Understand the project requirements and objectives through discussions with stakeholders.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/design.svg" alt="image"> </div>
                    <h3>Planning and Design</h3>
                    <p>Create a roadmap and architectural design for the application, outlining key components and
                        functionalities.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/environment.svg" alt="image"> </div>
                    <h3>Environment Setup</h3>
                    <p>Establish the development environment with essential tools like text editors and package
                        managers. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/coding.svg" alt="image"> </div>
                    <h3>Coding</h3>
                    <p> Write clean, modular, and reusable code according to the established design, following best
                        practices and coding standards.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/documentation.svg" alt="image"> </div>
                    <h3>Documentation</h3>
                    <p>Document the codebase, including APIs, functions, and modules, to facilitate understanding and
                        maintenance for other developers.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/deployment.svg" alt="image"> </div>
                    <h3>Deployment</h3>
                    <p>Deploy the application to staging or production configuring servers, databases, and other
                        necessary infrastructure components.</p>
                </div>
            </div>
        </div>
    </div>
</section>