import { Component } from '@angular/core';

@Component({
  selector: 'app-dot-net',
  templateUrl: './dot-net.component.html',
  styleUrl: './dot-net.component.scss'
})
export class DotNetComponent {

}
