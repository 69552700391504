<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>E-Commerce Solutions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-details-area pt-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Transforming Retail Dynamics with Dynamic Solutions</h3>
                        <p>Join the evolution of retail with our cutting-edge E-commerce solutions designed to
                            revolutionize your online presence. At Kautilyam, we understand the ever-changing landscape
                            of digital commerce and are committed to helping businesses adapt and thrive. Our
                            comprehensive suite of services includes everything from responsive website design to
                            advanced analytics and AI-powered recommendations. With a focus on scalability and
                            performance, we'll help you stay ahead of the curve and exceed customer expectations.
                            Embrace the future of retail with Kautilyam and embark on a journey of E-commerce evolution
                            that drives sustainable growth and success. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/e-commerce.png"
                            alt="image"> </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/e-commerce2.png"
                            alt="image"> </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <div class="features-text">
                            <h4> Blending Innovation and Strategy for Online Success</h4>
                            <p>Experience the perfect fusion of innovation and strategy with our comprehensive
                                E-commerce solutions at Kautilyam. We specialize in helping businesses harness the power
                                of digital commerce to achieve their goals and unlock new opportunities for growth. From
                                intuitive website design to seamless integration with third-party platforms and advanced
                                marketing automation, our team of experts will work closely with you to create a
                                customized E-commerce solution that drives results. With a focus on collaboration and
                                continuous improvement, we'll help you navigate the complexities of online retail and
                                stay ahead of the competition. Embrace the future of E-commerce with Kautilyam and
                                elevate your online business to new heights of success. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>