import { Component } from '@angular/core';

@Component({
  selector: 'app-kotlin',
  templateUrl: './kotlin.component.html',
  styleUrl: './kotlin.component.scss'
})
export class KotlinComponent {

}
