<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Right Sidebar</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Right Sidebar</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img1.jpg" alt="image"></a> </div>
                            <div class="content"> <span>05 March 2024</span>
                                <h3><a>Elevate your online presence through actionable insights from analytics.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img2.jpg" alt="image"></a> </div>
                            <div class="content"> <span>06 March 2024</span>
                                <h3><a>Boost your digital impact with insights-driven strategies from analytics</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img3.jpg" alt="image"></a> </div>
                            <div class="content"> <span>02 March 2023</span>
                                <h3><a>Optimize your website's effectiveness with data-driven decisions powered by
                                        analytics."</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img4.jpg" alt="image"></a> </div>
                            <div class="content"> <span>20 Fabruary 2024</span>
                                <h3><a>Drive growth for your digital platform by leveraging insights gleaned from
                                        analytics.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img5.jpg" alt="image"></a> </div>
                            <div class="content"> <span>25 Fabruary 2024</span>
                                <h3><a>Unlock the full potential of your online presence with strategic analytics
                                        implementation.</a></h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-item">
                            <div class="image"> <a><img src="assets/img/blog/img6.jpg" alt="image"></a> </div>
                            <div class="content"> <span>30 July 2023</span>
                                <h3><a>Enhance your website's engagement and conversion rates using analytics
                                        insights.</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_fria_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item"> <a class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info"> <time class="2023-06-30">March 07, 2024</time>
                                <h4 class="title usmall"><a>Driving digital success through data-driven decisions and
                                        analytics.</a></h4>
                            </div>
                        </article>
                        <article class="item"> <a class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info"> <time class="2023-06-30">March 08, 2024</time>
                                <h4 class="title usmall"><a>Achieving digital excellence through analytics-driven
                                        strategies.</a></h4>
                            </div>
                        </article>
                        <article class="item"> <a class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info"> <time class="2023-06-30">March 09, 2024</time>
                                <h4 class="title usmall"><a>Optimizing your online presence through strategic
                                        analytics.</a></h4>
                            </div>
                        </article>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>