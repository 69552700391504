<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Next js</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3> NEXT.JS DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Focused on crafting high-performance, scalable applications, we harness the capabilities of
                        Next.js to revolutionize your online footprint, guaranteeing a fluid and captivating user
                        journey across diverse platforms. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified Next js specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>4.8/5 stars</h2>
                            <p>overall review rating based on 60+ reviews </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>100 + </h2>
                            <p>Next js projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with Next js development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Advantages of Next.js </h3>
            <p>Inquiring about the distinctiveness of Next.js development? This framework comes highly recommended for
                companies seeking a strategic advantage in the competitive landscape. It empowers developers to
                seamlessly assemble visual components, establish data bindings, and define the underlying logic. Here
                lie the paramount advantages of Next.js </p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/simplicity.svg" alt="image"> </div>
                    <h3>Simplicity</h3>
                    <p>Next.js simplifies the development process with its intuitive and straightforward APIs, making it
                        easy to create and maintain applications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/rapid.svg" alt="image"> </div>
                    <h3>Rapid Development </h3>
                    <p> Build feature-rich web apps quickly with built-in features like automatic code splitting and hot
                        module replacement. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/performance.svg" alt="image"> </div>
                    <h3> Enhanced Performance</h3>
                    <p> Improve page load times and user experiences with features like automatic code splitting and
                        prefetching. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/seo.svg" alt="image"> </div>
                    <h3>SEO Optimization </h3>
                    <p>Achieve excellent SEO results with built-in server-side rendering for effective content indexing.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/generation.svg" alt="image"> </div>
                    <h3>Static Site Generation </h3>
                    <p>Generate HTML pages at build time for improved performance and simplified deployment. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/render.svg" alt="image"> </div>
                    <h3> Server-side Rendering</h3>
                    <p> Deliver fully rendered HTML pages to the client for faster initial page loads and better SEO.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/increment.svg" alt="image"> </div>
                    <h3> Incremental Adoption</h3>
                    <p> Add Next.js to existing projects or start new projects without significant architectural
                        changes. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/typescript.svg" alt="image"> </div>
                    <h3>TypeScript Support </h3>
                    <p> Enjoy type safety and improved developer productivity with excellent TypeScript support.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/community.svg" alt="image"> </div>
                    <h3> Active Community</h3>
                    <p>Benefit from a supportive community of developers providing resources and tutorials. </p>
                </div>
            </div>
        </div>
    </div>
</section>