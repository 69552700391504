<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Portfolio</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Portfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="agency-services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>A Gallery of Excellence: Our Portfolio</h2>
            <div class="bar"></div>
            <p>Our portfolio represents a gallery of excellence, displaying a diverse range of projects that showcase
                our commitment to innovation, expertise, and dedication to success. Each item within our portfolio tells
                a unique story of passion, creativity, and proficiency, demonstrating our ability to exceed expectations
                and deliver outstanding results.</p>
        </div>
        <div class="row" *ngIf="porfolioDetails && porfolioDetails.length">
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4" *ngFor="let item of porfolioDetails">
              <div class="single-agency-services p-3 bg-white rounded-3" [routerLink]="['/portfolio-detail']" [state]="{item: item.type}">
                <div class="image">
                  <img src="assets/img/portfolio/{{item.imageLink}}" alt="image" style="height: 230px;" class="rounded-3">
                </div>
                <div class="content rounded-3" style="padding: 20px;">
                  <h3 class="m-0">{{item.name}} - {{item.type}}</h3>
                </div>
              </div>
            </div>
          </div>
    </div>
</section>
