<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Frontend Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>ANGULAR DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Empower your projects with Kautilyam's Angular expertise. Our dedicated team stands ready to
                        transform your ideas into feature-rich, robust applications. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30+</h2>
                            <p>qualified Angular specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>45+ </h2>
                            <p>Angular projects delivered </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>30 +</h2>
                            <p>qualified Angular specialists worldwide </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <h2>8 years</h2>
                            <p>of experience with Angular development </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>Advantages of Angular: Enhancing Development Efficiency and Application Quality </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/modular.svg" alt="image"> </div>
                    <h3>Modular Development</h3>
                    <p>Angular enables developers to break down complex applications into smaller, reusable components,
                        enhancing maintainability and scalability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/data.svg" alt="image"> </div>
                    <h3>Two-Way Data Binding</h3>
                    <p>Angular's two-way data binding feature automatically synchronizes the model and the view,
                        simplifying development by reducing manual DOM manipulation. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/dependency.svg" alt="image"> </div>
                    <h3>Dependency Injection </h3>
                    <p>Angular's dependency injection system manages dependencies between components, promoting code
                        reusability, testability, and maintainability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/cross-platform.svg" alt="image"> </div>
                    <h3>Cross-Platform Development </h3>
                    <p>Angular supports building applications that run seamlessly across different platforms, including
                        web browsers, mobile devices, and desktops. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/built.svg" alt="image"> </div>
                    <h3>Built-in Testing <br> Support</h3>
                    <p>Angular provides built-in support for testing through tools like Jasmine and Protractor, enabling
                        developers to ensure the reliability and quality of their applications.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/communities.svg" alt="image"> </div>
                    <h3>Community and Ecosystem</h3>
                    <p>Angular's community and ecosystem provide comprehensive support, including documentation,
                        tutorials, libraries, and tools, fostering efficient application development. </p>
                </div>
            </div>
        </div>
    </div>
</section>