import { Component } from '@angular/core';

@Component({
  selector: 'app-app-development',
  templateUrl: './app-development.component.html',
  styleUrl: './app-development.component.scss'
})
export class AppDevelopmentComponent {

}
