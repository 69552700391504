<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image"> <!-- <img src="assets/img/about/about-2.webp" alt="image"> --> </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2>We lead in IT infrastructure and network management</h2>
                    <div class="bar"></div>
                    <p>Our company excels in providing comprehensive IT infrastructure and network management services,
                        ensuring that businesses can operate smoothly and efficiently in today's digital landscape.</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our History</a></li>
                            <li><a href="#">Our Missinon</a></li>
                            <li><a href="#">Friendly Support</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>A Journey Of Innovation And Growth</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Founded on innovation, with a small but dedicated
                                        team</li>
                                    <li><i class="flaticon-tick"></i> Established reputation for quality and customer
                                        satisfaction</li>
                                    <li><i class="flaticon-tick"></i> Developed groundbreaking products and services
                                    </li>
                                    <li><i class="flaticon-tick"></i> Milestones marked continuous evolution and
                                        innovation</li>
                                </ul>
                                <p>Join us on a voyage towards digital transformation with Kautilyam. Whether you seek
                                    to optimize workflows, revolutionize customer engagement, or pioneer new industry
                                    standards, our unwavering commitment to your success ensures we're by your side
                                    every step of the way, shaping a future of limitless possibilities together.</p>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>A Guide To Inspiring Innovative Ideas And Solutions</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Cultivate creativity and curiosity</li>
                                    <li><i class="flaticon-tick"></i> Provide comprehensive guidance</li>
                                    <li><i class="flaticon-tick"></i> Empower individual perspectives</li>
                                    <li><i class="flaticon-tick"></i> Foster collaborative environments</li>
                                </ul>
                                <p>Discover a comprehensive resource designed to inspire and ignite creativity within IT
                                    ventures. This guide offers practical strategies and actionable insights, empowering
                                    businesses to cultivate innovative ideas and solutions that propel success in
                                    today's dynamic digital landscape.</p>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>Techniques for Generating Innovative Ideas</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Unveiling Insights and Wisdom</li>
                                    <li><i class="flaticon-tick"></i> A Philosophical Journey towards Data-driven
                                        Decision-making</li>
                                    <li><i class="flaticon-tick"></i> Insights for Strategic Leadership</li>
                                    <li><i class="flaticon-tick"></i> Navigating Complexity and Uncertainty</li>
                                </ul>
                                <p>Discover proven methods for sparking creativity and generating innovative ideas in
                                    the realm of IT business. From brainstorming sessions to design thinking approaches,
                                    this guide provides actionable insights to fuel your company's growth and success
                                    through inventive solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="team-section pt-100 ptb-70 protfolio-section bg-color">
    <div class="container">
        <div class="section-title">
            <h2>Why Kautilyam?</h2>
            <p>We want everyone to be able to afford specialized teams for their business needs, so here we are,
                promising great outcomes on time and within a reasonable budget.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image"> <img src="assets/img/team/img1.png" alt="image"> </div>
                    <div class="content">
                        <h3>Experience</h3>
                        <p>Benefit from our vast experience, ensuring precise task completion and delivering outstanding
                            Blockchain solutions.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image"> <img src="assets/img/team/img2.png" alt="image"> </div>
                    <div class="content">
                        <h3>Cost Effective</h3>
                        <p>Our strength lies in understanding client needs, delivering exceptional results through
                            collaboration and robust IT support.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image"> <img src="assets/img/team/img3.png" alt="image"> </div>
                    <div class="content">
                        <h3>Team & Culture</h3>
                        <p>Elevating teamwork and fostering an atmosphere of continuous innovation, our culture is
                            fueled by a shared drive for excellence.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image"> <img src="assets/img/team/img4.png" alt="image"> </div>
                    <div class="content">
                        <h3>Infrastructure</h3>
                        <p>Our priority at Kautilyam is to build a robust infrastructure and provide a conducive work
                            environment for our team's success.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<section class="clients-section ptb-100 ">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <h2>Technologies We Work On </h2>
                <div class="bar"></div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <h3>Frontend Development</h3>
                    <p>Revamp your online image with our tailored frontend development solutions, leveraging
                        cutting-edge techniques to craft captivating and intuitive websites that leave a lasting impact.
                    </p>
                    <div class="d-flex justify-content-around pt-4"> <img src="assets/img/about/angular.svg" alt="image"
                            height="50px" width="50px"> <img src="assets/img/about/typescript.svg" alt="image"
                            height="50px" width="50px"> <img src="assets/img/about/react-js.svg" alt="image"
                            height="50px" width="50px"> <img src="assets/img/about/javascript.svg" alt="image"
                            height="50px" width="50px"> <img src="assets/img/about/html.svg" alt="image" height="50px"
                            width="50px"> </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <h3>Backend Development</h3>
                    <p>Enhance your digital framework with our proficient backend development services, guaranteeing
                        resilient, adaptable, and streamlined solutions crafted specifically for your requirements. </p>
                    <div class="d-flex justify-content-around pt-4"> <img src="assets/img/about/dotnet.svg" alt="image"
                            height="50px" width="50px"> <img src="assets/img/about/nodejs.svg" alt="image" height="50px"
                            width="50px"> <img src="assets/img/about/python.svg" alt="image" height="50px" width="50px">
                        <img src="assets/img/about/csharp.svg" alt="image" height="50px" width="50px"> </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="features-section pt-100 pb-70 protfolio-section bg-color">
    <div class="container">
        <div class="section-title">
            <h2>Our Core Values</h2>
            <p>We provide cutting-edge software solutions that add value to your business and provide the best user
                experience for your consumers to capitalize on the growth.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon"> <i class="flaticon-blueprint"></i> </div>
                    <h3>Knowledge</h3>
                    <p>Ongoing learning and knowledge exchange foster growth and innovation for our community.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon"> <i class="flaticon-update"></i> </div>
                    <h3>Leadership</h3>
                    <p>Developing strong leadership skills and promoting autonomy drive our organizational success.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon"> <i class="flaticon-security"></i> </div>
                    <h3>Friendly</h3>
                    <p>Cultivating a friendly atmosphere and fostering continuous improvement define our ethos at
                        Kautilyam.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-services-box">
                    <div class="icon"> <i class="flaticon-clock"></i> </div>
                    <h3>Collaborate</h3>
                    <p>Let's collaborate and leverage our tailored collaborative strategies to the drive mutual success.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="boxes-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Pillars of <b style="color: #0a64bc;"> Integrity</b> and <b style="color: #0a64bc;">Excellence</b>
            </h2>
            <div class="bar"></div>
            <p>As passionate learners and experienced innovators, we unite to craft solutions that transcend mere
                technology, focusing on the people who use it. Join us in our journey to blend human-centric innovation
                with cutting-edge tech, creating a future where technology serves humanity, not just function.</p>
        </div>
    </div>
</div>