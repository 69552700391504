<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{portfolioItem.name}} Porfolio</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>{{portfolioItem.name}} Porfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="work-gallery ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-12 col-xl-8">
                <ngx-gallery [options]="galleryOptions" [images]="portfolioItem.slider"
                    class="ngx-gallery"></ngx-gallery>
                <div class="mt-3">
                    <h2 class="heading fw-bold mb-2 mt-4">{{portfolioItem.subTitle}}</h2>
                    <div class="work-gallery-content mb-5"
                        *ngIf="portfolioItem.description && portfolioItem.description.length">
                        <p class="mb-4" *ngFor="let item of portfolioItem.description">{{item}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-xl-4 info">
                <div class="sticky-top" style="top: 30px;">
                    <a [href]="portfolioItem.redirectTo" target="_blank">
                        <button class="default-btn w-100 mb-3 default-portfolio-btn">View Project</button>
                    </a>
                    <ng-container *ngIf="portfolioItem.credentials && portfolioItem.credentials.length">
                        <div class="col mb-3" *ngFor="let item of portfolioItem.credentials">
                            <div class="application-cards d-flex gap-3 align-items-center">
                                <div class="content">
                                    <h4 class=" mb-2 fw-bold">{{item.type}}</h4>
                                    <p *ngIf="item.tenantKey && item.tenantValue" class="mb-1">
                                        <b>{{item.tenantKey}}</b>: {{item.tenantValue}}</p>
                                    <p class="mb-1"><b>{{item.userKey}}</b>: {{item.userValue}}</p>
                                    <p><b>{{item.passwordKey}}</b>: {{item.passwordValue}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="work-gallery-categories">
                        <h3 class=" fw-semibold mb-3">Technologies</h3>
                        <div *ngIf="portfolioItem.technologies && portfolioItem.technologies.length">
                            <div class="categorie-name d-flex align-items-center gap-2 bg-white"
                                *ngFor="let item of portfolioItem.technologies; let i = index"
                                [ngClass]="i !==portfolioItem.technologies.length - 1 ? 'mb-3' : ''">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                                <p class="m-0">
                                    {{item}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>