import { Component } from '@angular/core';

@Component({
  selector: 'app-react-js',
  templateUrl: './react-js.component.html',
  styleUrl: './react-js.component.scss'
})
export class ReactJsComponent {

}
