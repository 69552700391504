import { Component } from '@angular/core';

@Component({
  selector: 'app-c-plus',
  templateUrl: './c-plus.component.html',
  styleUrl: './c-plus.component.scss'
})
export class CPlusComponent {

}
