<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>App Development</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-video">
            <div class="text">
                <h3>Why Choose Kautilyam for Backend Development</h3>
                <p>TechTrack is a powerful mobile application designed specifically for IT professionals to streamline
                    and manage their projects effectively. Whether you're a software developer, IT manager, or system
                    administrator, TechTrack provides all the essential tools you need to plan, execute, and track your
                    IT projects with ease. </p>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Building a Dynamic Backend for Web Applications</h3>
                        <p>TechTrack empowers IT professionals to deliver projects on time, within budget, and with the
                            highest quality standards. Whether you're managing a small development team or overseeing
                            complex IT infrastructure projects, TechTrack is your go-to solution for efficient project
                            management in the digital age. </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/2.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <div class="features-text">
                            <h4>Project Planning:</h4>
                            <p>Create detailed project plans, set milestones, and define tasks to ensure a clear roadmap
                                for your IT projects.</p>
                        </div>
                        <div class="features-text">
                            <h4>Task Management: </h4>
                            <p> Assign tasks to team members, set deadlines, and track progress in real-time to keep
                                everyone aligned and on schedule.</p>
                        </div>
                        <div class="features-text">
                            <h4>Bug Tracking: </h4>
                            <p> Easily log and prioritize bugs, assign them to developers, and track their resolution to
                                ensure smooth project delivery.</p>
                        </div>
                        <div class="features-text">
                            <h4>Collaboration Tools: </h4>
                            <p> Foster collaboration among team members with built-in chat, file sharing, and discussion
                                forums to facilitate communication and problem-solving.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100 protfolio-section bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/android.svg" alt="image"> </div>
                    <h3>Android</h3>
                    <p>Android stands as the premier platform for developing innovative and advanced mobile
                        applications. </p> <a class="read-btn" href="/android">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/python.svg" alt="image"> </div>
                    <h3>python</h3>
                    <p>Python is a powerful and versatile programming language for all levels of expertise.</p> <a
                        class="read-btn" href="/python">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/react-native.svg" alt="image"> </div>
                    <h3>React-Native</h3>
                    <p>React Native is a framework for building cross-platform mobile applications using js and React.
                    </p> <a class="read-btn" href="/react-native">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/java.svg" alt="image"> </div>
                    <h3>Java</h3>
                    <p>Java is a popular OOP language known for its versatility and platform independence.</p> <a
                        class="read-btn" href="/java">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail ">
                    <div class="icon"> <img src="assets/img/services-details/flutter.svg" alt="image"> </div>
                    <h3>Swift</h3>
                    <p>Crafting seamless experiences across Apple's platforms with cutting-edge language. </p> <a
                        class="read-btn" href="/swift">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box-detail">
                    <div class="icon"> <img src="assets/img/services-details/kotlin.svg" alt="image"> </div>
                    <h3>Kotlin</h3>
                    <p>Kotlin, a concise JVM language, interoperable with Java, is modern and versatile. </p> <a
                        class="read-btn" href="/kotlin">Read More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<section class="services-details-area pt-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Our scope of <span> mobile app development services</span></h3>
                        <p>At Kautilyam, we know that every detail of the development process is crucial, and so we’ve
                            built the expertise to provide a full range of mobile application development services. We
                            can be responsible for design, coding, management, or integration, but we can also develop
                            your product from scratch into a fully functioning application. Regardless if you're a
                            start-up or a big enterprise, we'll adjust to your needs and create a beautiful digital
                            product that meets your expectations. Here are some of the mobile application development
                            services that we offer:</p>
                        <ul>
                            <li>Product Discovery & Product Research</li>
                            <li>UX Design, UI Design & Branding</li>
                            <li> Native Mobile App Development (Android or iOS)</li>
                            <li>Cross-Platform Mobile App Development</li>
                            <li> Preparing a Go-To-Market Strategy</li>
                            <li>QA Advisory & Consulting</li>
                            <li>Providing Customer Insights & Behavior Analytics</li>
                            <li>Maintenance & Support </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image"> <img src="assets/img/services-details/1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="section-title">
        <h1>Our Development Process</h1>
        <div class="bar"></div>
    </div>
    <div id="timeline">
        <div class="timeline-item">
            <div class="timeline-icon"><i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>01 : CONCEPTUALIZATION</h4>
                <ul>
                    <li>Identify app purpose and target problem. </li>
                    <li>Conduct market and competitor research.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>02 : VALIDATION AND STRATEGY </h4>
                <ul>
                    <li>Validate idea through market research.</li>
                    <li>Define goals, budget, and platform choice (iOS/Android). </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>03 : DESIGN AND DEVELOPMENT</h4>
                <ul>
                    <li>Design UI/UX and visuals.</li>
                    <li>Code, implement backend systems, and conduct ongoing tests.</li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>04 : TESTING & DEPLOYMENT</h4>
                <ul>
                    <li>Perform comprehensive testing; fix bugs. </li>
                    <li>Prepare for release and submit to app stores. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content">
                <h4>05 : DEPLOY THE APP</h4>
                <ul>
                    <li>Coordinate launch, monitor performance, and address issues. </li>
                </ul>
            </div>
        </div>
        <div class="timeline-item">
            <div class="timeline-icon"> <i class="fa-solid fa-star-half-stroke"></i> </div>
            <div class="timeline-content right">
                <h4>06 : GROWTH AND IMPROVEMENT </h4>
                <ul>
                    <li>Regularly update based on user feedback. </li>
                    <li>Implement monetization strategies and analyze data for enhancements.</li>
                </ul>
            </div>
        </div>
    </div>
</div>