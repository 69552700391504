<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-box pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box"> <i class="flaticon-pin"></i>
                    <div class="content-title">
                        <h3>Address</h3>
                        <p>309, Sunrise Commercial Complex,<br> Lajamni Chowk, Mota Varachha, Surat, Gujarat <br> 394105
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ">
                <div class="single-contact-box"> <i class="flaticon-phone-call"></i>
                    <div class="content-title">
                        <h3>Get In Touch</h3>
                        <p><a href="tel: (+91) 7600 420 470"> (+91) 7600 420 470</a></p>
                        <p>Opening Hours: <br> Mon to Fri: 9:30 AM to 7:00 PM <br> Sat: 9:30 AM to 2:00 PM</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-contact-box"> <i class="flaticon-envelope"></i>
                    <div class="content-title">
                        <h3>Have Questions?</h3>
                        <p>When it comes to managing IT for your business, you need an expert. That’s where we come in.
                        </p>
                        <a href="mailto:bdm&#64;kautilyam.com">bdm&#64;kautilyam.com</a>
                        <a href="mailto:info&#64;kautilyam.com">info&#64;kautilyam.com</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-text">
                    <h3>Do you have any inquiries regarding our company? </h3>
                </div>
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="submitForm()">
                        <div class="form-group"> <label for="name">Name</label> <input id="name" type="text"
                                formControlName="name" class="form-control" placeholder="Name">
                            <div *ngIf="contactForm.controls['name'].invalid && contactForm.controls['name'].touched">
                                <small style="color: red;" *ngIf="contactForm.controls['name'].errors.required">Name is
                                    Required.</small> </div>
                        </div>
                        <div class="form-group"> <label for="email">Email</label> <input id="email" type="email"
                                formControlName="email" class="form-control" placeholder="Your Email">
                            <div *ngIf="contactForm.controls['email'].invalid && contactForm.controls['email'].touched">
                                <small style="color: red;"
                                    *ngIf="contactForm.controls['email'].errors['required']">Email is Required.</small>
                                <small style="color: red;" *ngIf="contactForm.controls['email'].errors['email']">Invalid
                                    email format.</small> </div>
                        </div>
                        <div class="form-group">
                             <label for="contact">Contact</label>
                             <input id="contact" type="contact" formControlName="contact" class="form-control" placeholder="Your Contact"
                                maxlength="10"> </div>

                        <div class="form-group"> <label for="msg_subject">Subject</label> <input id="msg_subject"
                                type="text" formControlName="msg_subject" class="form-control"
                                placeholder="Your Subject">
                            <div
                                *ngIf="contactForm.controls['msg_subject'].invalid && contactForm.controls['msg_subject'].touched">
                                <small style="color: red;"
                                    *ngIf="contactForm.controls['msg_subject'].errors['required']">Subject is
                                    required.</small> </div>
                        </div>
                        <div class="form-group"> <label for="message">Message</label> <textarea id="message"
                                class="form-control" formControlName="message" placeholder="Your Message"
                                rows="6"></textarea> </div>
                        <div class="send-btn"> <button type="submit" class="default-btn"
                                [disabled]="contactForm.invalid">Send Message</button> </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-image"> <img src="assets/img/contact.webp" alt="image"> </div>
            </div>
        </div>
    </div>
</section>
