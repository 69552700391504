import { Component } from '@angular/core';

@Component({
  selector: 'app-node-js',
  templateUrl: './node-js.component.html',
  styleUrl: './node-js.component.scss'
})
export class NodeJsComponent {

}
