import { Component } from '@angular/core';

@Component({
  selector: 'app-graphic-design',
  templateUrl: './graphic-design.component.html',
  styleUrl: './graphic-design.component.scss'
})
export class GraphicDesignComponent {

}
