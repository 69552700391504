<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>HTML</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>HTML DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Empower your projects with Kautilyam's HTML expertise. Our dedicated team stands ready to
                        transform your ideas into feature-rich, robust applications. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12"> <img src="assets/img/icon/html.svg" alt="image"> </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>WHY CHOOSE KAUTILYAM FOR HTML DEVELOPMENT </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/simplicity.svg" alt="image"> </div>
                    <h3>Simplicity</h3>
                    <p> HTML offers a straightforward markup language for structuring web content.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/universal -compatibility.svg" alt="image"> </div>
                    <h3>Universal Compatibility</h3>
                    <p>Compatible with all web browsers and platforms, ensuring broad accessibility.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/learning.svg" alt="image"> </div>
                    <h3>Ease of Learning</h3>
                    <p>Simple syntax and clear structure make HTML easy for beginners to learn.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/semantics.svg" alt="image"> </div>
                    <h3>Semantic Markup</h3>
                    <p>Provides semantic elements to define the meaning and purpose of content, enhancing SEO and
                        accessibility.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/integration.svg" alt="image"> </div>
                    <h3>Integration Technologies</h3>
                    <p>Easily integrates with CSS and JavaScript for styling and interactivity.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/widely-supported.svg" alt="image"> </div>
                    <h3>Widely Supported</h3>
                    <p> Supported by a vast ecosystem of tools, libraries, and frameworks for web development.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/platform.svg" alt="image"> </div>
                    <h3>Platform Independence</h3>
                    <p> Works across different devices and operating systems, ensuring consistent rendering.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/accessibility.svg" alt="image"> </div>
                    <h3>Accessibility</h3>
                    <p>Supports accessibility features like alt text and semantic elements for creating inclusive web
                        experiences.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/render.svg" alt="image"> </div>
                    <h3>Fast Rendering</h3>
                    <p>Lightweight markup language that renders quickly in web browsers, optimizing page load times.</p>
                </div>
            </div>
        </div>
    </div>
</section>