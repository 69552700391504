import { Component } from '@angular/core';

@Component({
  selector: 'app-backend-development',
  templateUrl: './backend-development.component.html',
  styleUrl: './backend-development.component.scss'
})
export class BackendDevelopmentComponent {

}
