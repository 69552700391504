<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-4">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>CSS</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>CSS DEVELOPMENT SERVICES</h3>
                    <div class="bar"></div>
                    <p>Empower your projects with Kautilyam's CSS expertise. Our dedicated team stands ready to
                        transform your ideas into feature-rich, robust applications. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12"> <img src="assets/img/icon/css.svg" alt="image"> </div>
        </div>
    </div>
</section>
<section class="services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>WHY CHOOSE KAUTILYAM FOR CSS DEVELOPMENT </h3>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/separation.svg" alt="image"> </div>
                    <h3>Separation of Concerns </h3>
                    <p>Allows separation of style from content, enhancing maintainability and scalability.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/consistency.svg" alt="image"> </div>
                    <h3>Consistent Styling</h3>
                    <p> Provides consistent styling across multiple web pages by defining styles in external CSS files.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/responsive.svg" alt="image"> </div>
                    <h3>Responsive Design</h3>
                    <p> Enables the creation of responsive layouts that adapt to different screen sizes and devices.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/modular.svg" alt="image"> </div>
                    <h3>Modularization</h3>
                    <p>Supports modularization of styles through classes and IDs, facilitating code reuse and
                        organization.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/update.svg" alt="image"> </div>
                    <h3>Ease of Updates</h3>
                    <p>Allows easy updates to styling across multiple pages by modifying a single CSS file.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon"> <img src="assets/img/icon/flexibility.svg" alt="image"> </div>
                    <h3>Flexibility</h3>
                    <p> Offers flexibility in design through various layout techniques like flexbox and grid.</p>
                </div>
            </div>
        </div>
    </div>
</section>