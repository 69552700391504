import { Component } from '@angular/core';

@Component({
  selector: 'app-azure',
  templateUrl: './azure.component.html',
  styleUrl: './azure.component.scss'
})
export class AzureComponent {

}
