<app-navbar-one></app-navbar-one>
<div class="main-banner-area-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content"> <span>Tech Empowerment</span>
                            <h1>Driving Growth through Innovative IT Solutions</h1>
                            <p>Revolutionizing growth pathways with ingenious IT solutions. Bridging possibilities and
                                prosperity through tech-driven innovation.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <!-- <img src="assets/img/home-four/img1.png" alt="image" loading="lazy"> --> <img
                                src="assets/img/home-four/img1.webp"
                                sizes="(max-width: 600px) 480px, (max-width: 1200px) 800px, 1200px"
                                alt="Tech Empowerment" loading="lazy"> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>
<section class="process-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="process-content">
                    <div class="number">01</div>
                    <h3>Frontend Development</h3>
                    <div class="image"> <img src="assets/img/process/1.webp" alt="image"> </div>
                    <div class="content">
                        <p> Frontend development involves creating the user interface and experience of a website or
                            application.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="process-content">
                    <div class="number">02</div>
                    <h3>Backend Development</h3>
                    <div class="image"> <img src="assets/img/process/2.webp" alt="image"> </div>
                    <div class="content">
                        <p>Backend development involves building and maintaining the server-side logic and databases of
                            a web application.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ">
                <div class="process-content">
                    <div class="number">03</div>
                    <h3>Software Development</h3>
                    <div class="image"> <img src="assets/img/process/3.webp" alt="image"> </div>
                    <div class="content">
                        <p>Software development involves creating and maintaining computer programs to meet specific
                            requirements.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ">
                <div class="process-content">
                    <div class="number">04</div>
                    <h3> App Development</h3>
                    <div class="image"> <img src="assets/img/process/4.webp" alt="image"> </div>
                    <div class="content">
                        <p>In mobile app development, we craft innovative solutions to bring your ideas to life on every
                            screen. </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ">
                <div class="process-content">
                    <div class="number">05</div>
                    <h3>E-commerce Solutions</h3>
                    <div class="image"> <img src="assets/img/process/5.webp" alt="image"> </div>
                    <div class="content">
                        <p>Providing comprehensive e-commerce solutions for seamless online shopping experiences.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ">
                <div class="process-content">
                    <div class="number">06</div>
                    <h3>Blockchain</h3>
                    <div class="image"> <img src="assets/img/process/6.webp" alt="image"> </div>
                    <div class="content">
                        <p>Blockchain securely records transactions across multiple computers, ensuring transparency.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="about-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image-home"> <!-- <img src="assets/img/about/about2.jpg" alt="image"> --> </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2> Pioneering Innovation for a Sustainable Future</h2>
                    <div class="bar"></div>
                    <p> encompasses our dedication to developing cutting-edge solutions that address global challenges
                        while promoting environmental stewardship, shaping a greener and more resilient world through
                        forward-thinking strategies and technologies.</p>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">Our History</a></li>
                            <li><a href="#">Our Missinon</a></li>
                            <li><a href="#">Friendly Support</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>A Journey of Innovation and Growth</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Founded on innovation, with a small but dedicated
                                        team</li>
                                    <li><i class="flaticon-tick"></i>Established reputation for quality and customer
                                        satisfaction</li>
                                    <li><i class="flaticon-tick"></i> Developed groundbreaking products and services
                                    </li>
                                    <li><i class="flaticon-tick"></i>Milestones marked continuous evolution and
                                        innovation</li>
                                </ul>
                                <p>Join us on a voyage towards digital transformation with Kautilyam. Whether you seek
                                    to optimize workflows, revolutionize customer engagement, or pioneer new industry
                                    standards, our unwavering commitment to your success ensures we're by your side
                                    every step of the way, shaping a future of limitless possibilities together.</p>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3>A Guide to Inspiring Innovative Ideas and Solutions</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Cultivate creativity and curiosity</li>
                                    <li><i class="flaticon-tick"></i>Provide comprehensive guidance</li>
                                    <li><i class="flaticon-tick"></i> Empower individual perspectives</li>
                                    <li><i class="flaticon-tick"></i>Foster collaborative environments</li>
                                </ul>
                                <p>Discover a comprehensive resource designed to inspire and ignite creativity within IT
                                    ventures. This guide offers practical strategies and actionable insights, empowering
                                    businesses to cultivate innovative ideas and solutions that propel success in
                                    today's dynamic digital landscape.</p>
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <h3> Techniques for Generating Innovative Ideas</h3>
                                </div>
                                <ul class="list">
                                    <li><i class="flaticon-tick"></i> Unveiling Insights and Wisdom</li>
                                    <li><i class="flaticon-tick"></i> A Philosophical Journey towards Data-driven
                                        Decision-making</li>
                                    <li><i class="flaticon-tick"></i> Insights for Strategic Leadership</li>
                                    <li><i class="flaticon-tick"></i> Navigating Complexity and Uncertainty</li>
                                </ul>
                                <p>Discover proven methods for sparking creativity and generating innovative ideas in
                                    the realm of IT business. From brainstorming sessions to design thinking approaches,
                                    this guide provides actionable insights to fuel your company's growth and success
                                    through inventive solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>IT Agency Services</h2>
            <p>Explore our array of services, designed to meet your diverse needs and exceed expectations. From
                innovative solutions to unparalleled expertise, we deliver excellence at every turn.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-it"></i> </div>
                    <h3>Frontend Development</h3>
                    <p>Frontend development involves creating the user interface and experience of a website or
                        application.</p> <a class="read-btn" href="/frontend-development">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-setting"></i> </div>
                    <h3>Backend Development</h3>
                    <p>Backend development involves building and maintaining the server-side logic and databases of a
                        web application.</p> <a class="read-btn" href="/backend-development">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-promotion"></i> </div>
                    <h3>Graphic Design</h3>
                    <p>Craft visually captivating designs that captivate and inspire through innovative graphic elements
                        and creative composition. </p> <a class="read-btn" href="/graphic-design">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-optimize"></i> </div>
                    <h3>App Development</h3>
                    <p>App development merges coding, design, and user experience principles for mobile devices. </p> <a
                        class="read-btn" href="/app-development">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-cloud-computing"></i> </div>
                    <h3>E-Commerce Solutions</h3>
                    <p>Providing comprehensive e-commerce solutions for seamless online shopping experiences.</p> <a
                        class="read-btn" href="/e-commerce-solution">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-two">
                    <div class="icon"> <i class="flaticon-laptop"></i> </div>
                    <h3>Blockchain</h3>
                    <p>Blockchain securely records transactions across multiple computers, ensuring transparency.</p> <a
                        class="read-btn" href="/blockchain">Read More</a>
                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>
<section class="protfolio-section bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Impressive</span> Portfolio</h2>
            <p>Dive into our impressive portfolio showcasing our expertise, creativity, and success stories. From
                cutting-edge projects to impactful collaborations, discover how we've consistently delivered exceptional
                results for our clients.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"><a href="https://vertexmarket-ecommerce.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro1.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content">
                            <h3>VertexMarket- ECommerce</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"> <a href="https://axion-dealscoupons.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro2.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Axion Deals coupons</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"> <a href="https://wellnessa-hospital.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro3.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Wellnessa Hospital</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"> <a href="https://digipluse-digital-market.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro4.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Digipluse Digital-Market</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"> <a href="https://luxehavens-hotel.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro5.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Luxehavens Hotel</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box"> <a href="https://tradermindtech-stackmarket.web.app/">
                        <div class="image"> <img src="assets/img/protfolio/pro6.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>TraderMindTech</h3>
                        </div>
                    </a> </div>
            </div>
        </div>
    </div>
</section>
<section class="review-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>We have Completed <span>287+</span> Projects <span>Successfully</span></h2>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="review-content">
                    <h3>How to Generate <span>Creative</span> Ideas for <span>your</span> IT Business</h3>
                    <div class="bar"></div>
                    <p>Foster a culture of innovation in your IT business with strategies for generating creative ideas.
                        From collaborative brainstorming sessions to leveraging emerging technologies, explore methods
                        to spark inspiration and drive forward-thinking solutions. </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-check"></i> </div>
                            <h3><span class="odometer" data-count="287">00</span></h3>
                            <p>Completed Project</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-happy"></i> </div>
                            <h3><span class="odometer" data-count="12">00</span></h3>
                            <p>Year Experience</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-technical-support"></i> </div>
                            <h3><span class="odometer" data-count="100">00</span>%</h3>
                            <p>Satisfaction</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon"> <i class="flaticon-trophy"></i> </div>
                            <h3><span class="odometer" data-count="100">00</span>+</h3>
                            <p>Clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="subscribe-inner-area ptb-100">
    <div class="container">
        <div class="subscribe-inner-text">
            <h2>Optimize Your Business Solutions with Timely Updates and Expert Insights for Success</h2>
        </div>
    </div>
</section>
<section class="clients-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p> What our clients say about our services speaks volumes about the quality and dedication we bring to our
                work.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon"> <i class="flaticon-left-quotes-sign"></i> </div>
                <p>I'm reassured knowing that my technology operates seamlessly in the background, allowing our business
                    to run smoothly without disruptions. Returning to Kautilyam has brought me peace of mind.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon"> <i class="flaticon-left-quotes-sign"></i> </div>
                <p>Thanks to Kautilyam, I can focus on my core business activities without worrying about technical
                    glitches disrupting our operations. Their reliable services have restored my confidence in our
                    technology infrastructure.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon"> <i class="flaticon-left-quotes-sign"></i> </div>
                <p>Kautilyam's seamless technology management has alleviated any concerns about disruptions to our
                    business operations. Their efficient services have reinstated my trust in our systems, allowing me
                    to concentrate on our company's growth.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3> <!-- <span>Web Designer</span> -->
                </div>
            </div>
        </div>
    </div>
</section>